import CryptoJS from 'crypto-js';
import store from 'store';

const get = key => store.encryptGet(key);

const set = (key, data) => store.encryptSet(key, data);

const remove = key => store.remove(key);

const clear = () => store.clearAll();

const init = storeKey => {
  function cryptoPlugin() {
    return {
      encryptSet(_, key, data) {
        const encryptValue = CryptoJS.AES.encrypt(JSON.stringify(data), storeKey).toString();
        store.set(key, encryptValue);
      },
      encryptGet(_, key) {
        const encryptedValue = this.get(key);
        if (!encryptedValue) return encryptedValue;
        const bytes = CryptoJS.AES.decrypt(encryptedValue, storeKey);
        const bytesString = bytes.toString(CryptoJS.enc.Utf8);
        try {
          return JSON.parse(bytesString);
        } catch {
          return bytesString;
        }
      },
    };
  }
  store.addPlugin(cryptoPlugin);
};

export default {
  get,
  set,
  remove,
  clear,
  init,
};
