import { Reducer } from 'redux';
import { Subscription } from 'dva';
import { Effect } from '@/models/connect.d';
import services from '@/services';
import { storage, setSystemParams } from '@/utils';

export interface SystemParameterState {
  unitValue: number;
  placesValue: number;
  project: any;
  volumeUnit: [];
  digitAccuracy: [];
  dict: any;
  dmaParam: [];
  sysParam: [];
  measParams: {};
}

export interface SystemManagementType {
  namespace: 'systemManagement';
  state: SystemParameterState;
  effects: {
    name: Effect;
    digitAccuracy: Effect;
    volumeUnit: Effect;
    updateName: Effect;
  };
  reducers: {
    setName: Reducer<SystemParameterState>;
  };
  subscriptions: { setup: Subscription };
}

export default {
  namespace: 'systemManagement',
  state: {
    unitValue: 0,
    placesValue: 0,
    project: {
      id: 0,
      name: '',
      param: '',
      value: '',
    },
    volumeUnit: [],
    digitAccuracy: [],
    dict: [],
    reason: [],
    dmaParam: [],
    sysParam: [],
    measParams: {},
  },
  effects: {
    *fetch({ payload }, { call, put }) {
      const res = yield call(services.sys.name, payload);
      if (res.success) {
        yield put({
          type: 'setName',
          payload: res.result,
        });
      }
      return res;
    },
    *fetchDigitAccuracy({ payload }, { call, put }) {
      const res = yield call(services.sys.digitAccuracy, payload);
      if (res.success) {
        yield put({
          type: 'setDigitAccuracy',
          payload: res.result,
        });
      }
      return res;
    },
    *fetchVolumeUnit({ payload }, { call, put }) {
      const res = yield call(services.sys.volumeUnit, payload);
      if (res.success) {
        yield put({
          type: 'setVolumeUnit',
          payload: res.result,
        });
      }
      return res;
    },
    *updateName({ payload }, { call }) {
      const res = yield call(services.sys.updateName, payload);
      return res;
    },
    *deleteReason({ payload }, { call, put }) {
      const res = yield call(services.sys.deleteReason, payload);
      if (res.success) {
        yield put({
          type: 'reason',
          payload: res.result,
        });
      }
      return res;
    },
    *getDictByType({ payload }, { call, put }) {
      const res = yield call(services.sys.getDictByType, {}, payload);
      if (res.success) {
        yield put({
          type: 'getDict',
          payload: res.result,
        });
      }
      return res;
    },
    *getDmaParam({ payload }, { call, put }) {
      const res = yield call(services.sys.dmaParam, payload);
      if (res.success) {
        yield put({
          type: 'get_DmaParam',
          payload: res.result,
        });
      }
      return res;
    },
    *getSysParam({ payload }, { call, put }) {
      const res = yield call(services.sys.getParam, payload);
      if (res.success) {
        yield put({
          type: 'get_SysParam',
          payload: res.result,
        });
      }
      return res;
    },
    *savaAllDict({ payload }, { call, put }) {
      const res = yield call(services.sys.dictAll, payload);
      if (res.success) {
        yield put({
          type: 'sava_AllDict',
          payload: res.result,
        });
      }
      return res;
    },
  },
  reducers: {
    setName(state, { payload }) {
      return {
        ...state,
        project: payload || {},
      };
    },
    setDigitAccuracy(state, { payload }) {
      return {
        ...state,
        digitAccuracy: payload || {},
      };
    },
    setVolumeUnit(state, { payload }) {
      return {
        ...state,
        volumeUnit: payload || {},
      };
    },
    updateName(state, { payload }) {
      return {
        ...state,
        project: payload,
      };
    },
    reason(state, { payload }) {
      return {
        ...state,
        reason: payload,
      };
    },
    getDict(state, { payload }) {
      return {
        ...state,
        dict: payload,
      };
    },
    get_DmaParam(state, { payload }) {
      return {
        ...state,
        dmaParam: payload,
      };
    },
    get_SysParam(state, { payload }) {
      const oldMeasParams = storage.get('measParams');
      const oldSysParams = storage.get('sysParams');
      const { measParams, params } = setSystemParams(payload);
      if (!oldMeasParams || JSON.stringify(oldMeasParams) !== JSON.stringify(measParams)) {
        storage.set('measParams', measParams);
      }
      if (!oldSysParams || JSON.stringify(oldSysParams) !== JSON.stringify(params)) {
        storage.set('sysParams', params);
      }
      return {
        ...state,
        sysParam: payload,
        measParams,
        params,
      };
    },
    sava_AllDict(state, { payload }) {
      const dictAll = storage.get('dictAll');
      if (!dictAll || JSON.stringify(dictAll) !== JSON.stringify(payload)) {
        storage.set('dictAll', payload);
      }
      return {
        ...state,
      };
    },
  },
  subscriptions: {},
};
