import { storage } from '@/utils';

export function getProjectInfo() {
  return storage.get('projectInfo');
}

export function setProjectInfo(project: Record<string, any>) {
  if (!project) return;
  storage.set('projectInfo', project);
}
