import { Reducer } from 'redux';
import { Subscription } from 'dva';
import { Effect } from '@/models/connect.d';
import services from '@/services';

export interface RoleManagementState {
  dataSource: [];
  permission: [];
  personalPermission: [];
}

export interface RoleManagementType {
  namespace: 'roleManagement';
  state: RoleManagementState;
  effects: {
    createRole: Effect;
    updateRole: Effect;
    role: Effect;
    roles: Effect;
    deleteRole: Effect;
    deleteRoles: Effect;
    permissions: Effect;
    personalPermission: Effect;
  };
  reducers: {
    getRoles: Reducer<RoleManagementState>;
  };
  subscriptions: { setup: Subscription };
}

export default {
  namespace: 'roleManagement',
  state: {
    dataSource: [],
    permission: [],
    personalPermission: [],
  },
  effects: {
    *addRole({ payload }, { call, put }) {
      const res = yield call(services.role.createRole, payload.body);
      if (res.success) {
        yield put({
          type: 'add_Role',
          payload: res.result,
        });
      }
      return res;
    },
    *deleteRole({ payload }, { call, put }) {
      const res = yield call(services.role.deleteRole, payload);
      if (res.success) {
        yield put({
          type: 'delete_Role',
          payload: res.result,
        });
      }
      return res;
    },
    *updateRole({ payload }, { call, put }) {
      const res = yield call(services.role.updateRole, payload.body);
      if (res.success) {
        yield put({
          type: 'update_Role',
          payload: res.result,
        });
      }
      return res;
    },
    *getRoleList({ payload }, { call, put }) {
      const res = yield call(services.role.getRoleList, payload);
      if (res.success) {
        yield put({
          type: 'roleList',
          payload: res.result,
        });
      }
      return res;
    },
    *fetchPermissions({ payload }, { call, put }) {
      const res = yield call(services.resource.permissions, payload);
      if (res.success) {
        yield put({
          type: 'getPermissions',
          payload: res.result,
        });
      }
      return res;
    },
    *fetchPermission({ payload }, { call, put }) {
      const res = yield call(services.resource.permission, payload);
      if (res.success) {
        yield put({
          type: 'getPermission',
          payload: res.result,
        });
      }
      return res;
    },
  },
  reducers: {
    add_Role(state) {
      return {
        ...state,
      };
    },
    delete_Role(state) {
      return {
        ...state,
      };
    },
    update_Role(state) {
      return {
        ...state,
      };
    },
    roleList(state, { payload }) {
      return {
        ...state,
        roleList: payload,
      };
    },
    getPermissions(state, { payload }) {
      return {
        ...state,
        permission: payload,
      };
    },
    getPermission(state, { payload }) {
      return {
        ...state,
        personalPermission: payload,
      };
    },
  },
  subscriptions: {},
};
