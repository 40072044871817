// 获得树形组织拼装名称
export const treeFindPath = (treeData, func, path = []) => {
  if (!treeData) return [];
  for (const data of treeData) {
    path.push(data.title);
    if (func(data)) return path;
    if (data.children) {
      const findChildren = treeFindPath(data.children, func, path);
      if (findChildren.length) return findChildren;
    }
    path.pop();
  }
  return [];
};
