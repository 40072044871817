import React from 'react';
import { Spin } from 'antd';
import { LoadingIcon } from '@/components/Icon';

const PageLoading: React.FC = () => (
  <div style={{ paddingTop: 100, textAlign: 'center' }}>
    <Spin size="large" indicator={<LoadingIcon style={{ width: '60px', height: 'auto' }} />} />
  </div>
);

export default PageLoading;
