import axios, { AxiosInstance } from 'axios';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import pathToRegexp from 'path-to-regexp';
import { notification } from 'antd';
import qs from 'qs';
import { apiPrefix } from '@/services/api';
import { resetTokenAndReattemptRequest, isTokenExpiredError, getAccessToken } from './token';
import { codeMessage } from '@/utils/constants';

const { CancelToken } = axios;

const CANCEL_REQUEST_MESSAGE = 'cancel request';

const instance: AxiosInstance = axios.create({
  baseURL: apiPrefix,
  timeout: 1000 * 60,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
});

instance.interceptors.request.use(async req => {
  req.headers.Authorization = `Bearer ${getAccessToken()}`;
  return req;
});

instance.interceptors.response.use(
  function onFulfilled(response) {
    return response;
  },
  function onRejected(error) {
    const errorResponse = error.response;
    if (isTokenExpiredError(errorResponse)) {
      return resetTokenAndReattemptRequest(error);
    }

    return Promise.reject(error);
  }
);

(window as any).cancelRequest = new Map();

export default function request(options) {
  const { data, method = 'get', otherOptions = '{}' } = options;
  let { url } = options;
  const cloneData = cloneDeep(data);

  let domain: string = '';
  const urlMatch = url.match(/[a-zA-z]+:\/\/[^/]*/);
  if (urlMatch) {
    [domain] = urlMatch;
    url = url.slice(domain.length);
  }

  const match = pathToRegexp.parse(url);
  url = pathToRegexp.compile(url)(data);
  match.forEach(item => {
    if (item instanceof Object && item.name in cloneData) {
      delete cloneData[item.name];
    }
  });
  url = domain + url;

  const newOptions = { ...options, ...JSON.parse(otherOptions) };

  newOptions.url =
    method.toLocaleLowerCase() === 'get'
      ? `${url}${isEmpty(cloneData) ? '' : '?'}${qs.stringify(cloneData)}`
      : url;

  newOptions.cancelToken = new CancelToken(cancel => {
    (window as any).cancelRequest.set(Symbol(Date.now()), {
      pathname: window.location.pathname,
      cancel,
    });
  });

  return instance(newOptions)
    .then(response => checkStatus(response))
    .then(async res => {
      const result = getMsgAndStatusCode('success', res);
      return Promise.resolve(result);
    })
    .catch(error => errorHandle(options, error));
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const errorText = codeMessage[response.status] || response.statusText;
  notification.error({
    message: `request error ${response.status}: ${response.url}`,
    description: errorText,
  });
  const error = new Error(errorText);
  error.name = response.status;
  error.response = response;
  console.log('checkStatus', error);
  throw error;
}

function getMsgAndStatusCode(type, res, errMessage) {
  if (type === 'success') {
    const { data } = res;
    return {
      success: true,
      result: data,
    };
  }

  if (String(errMessage) === CANCEL_REQUEST_MESSAGE) {
    return {
      success: false,
    };
  }

  if (res && res instanceof Object) {
    const { data: errorData } = res;
    return {
      success: false,
      statusCode: res.status,
      error: {
        type: errorData.type || 'unknownError',
        message: errorData.message || '服务器发生错误,请重试',
      },
    };
  }
  return {
    success: false,
    statusCode: 600,
    error: {
      type: 'internalServerError',
      message: '服务器发生错误,请重试',
    },
  };
}

async function errorHandle({ url }, error) {
  const { response, message: errMessage } = error;

  const result = getMsgAndStatusCode('error', response, errMessage);
  if (
    response &&
    (response.status === 'TypeError' || response.status === 401) &&
    !url.includes('logout') &&
    !url.includes('login')
  ) {
    (window as any).g_app._store.dispatch({
      type: 'auth/logout',
    });
  }

  return Promise.resolve(result);
}
