import React from 'react';
import pathToRegexp from 'path-to-regexp';
import Exception from '@/components/Exception';
import { arrayDeepFind } from '@/utils';
import { getPermissions, checkPermission } from '@/utils/permission';
import routes from '../../config/routes';

export default ({ children, location }) => {
  function getAuth() {
    if (location.pathname === '/login') return true;

    const currentRoute = arrayDeepFind(
      routes,
      'routes',
      i => i.path && pathToRegexp(i.path).test(location.pathname)
    );
    const permissions = getPermissions();
    if (!permissions || !permissions.length) {
      window.g_app._store.dispatch({
        type: 'auth/logout',
      });
      return;
    }
    if (!currentRoute.authKey) return true;
    if (checkPermission(currentRoute.authKey)) {
      return true;
    }
    return false;
  }

  const isAuth = getAuth();
  if (isAuth) return children;

  return <Exception type="403" />;
};
