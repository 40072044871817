import { parse } from 'qs';
import isPlainObject from 'lodash/isPlainObject';

export { default as storage } from './storage';
export { default as request } from './request';
export { default as reg } from './reg';
export { DateFormat, parseToTimeStamp, formatTimeStamp, DATE_FORMATS } from './DateFormat';
export { tableSort, tableFilter } from './sortAndFilter';
export { treeFindPath } from './treeChangeArr';

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg: RegExp = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export const isUrl: boolean = (path: string): boolean => reg.test(path);

export function getPageQuery(): string {
  return parse(window.location.href.split('?')[1]);
}

export const sleep: Promise = time =>
  new Promise((resolve): void => setTimeout(resolve, time * 1000));

/**
 * 计算字符串长度（汉字算两个字符，字母数字算一个）
 * @param val
 * @returns {number}
 */
export const getByteLen: number = (val: string[]) => {
  let len: number = 0;
  if (val) {
    for (let i: number = 0; i < val.length; i += 1) {
      const length: number = val.charCodeAt(i);
      if (length >= 0 && length <= 128) {
        len += 1;
      } else {
        len += 2;
      }
    }
  }
  return len;
};
export function templateFilter(template: Record<string, any>): Record<string, string> {
  if (!template) {
    return template;
  }
  return {
    ...template,
    keys: template.keys
      ? template.keys.map(i => ({
          key: i.key,
          tableDef: {
            sort: i.tableDef ? i.tableDef.sort : undefined,
            // sortable: i.tableDef ? i.tableDef.sortable : false,
            // filterable: i.tableDef ? i.tableDef.filterable : false,
            singleValueFilter: i.tableDef ? i.tableDef.singleValueFilter : undefined,
            sortTs: i.tableDef ? i.tableDef.sortTs : undefined,
            multiValueFilter:
              i.tableDef && i.tableDef.multiValueFilter
                ? {
                    type: i.tableDef.multiValueFilter.type,
                    values: i.tableDef.multiValueFilter.distinctValues,
                  }
                : undefined,
            rangeFilter: i.tableDef ? i.tableDef.rangeFilter : undefined,
          },
          unit: i.unit,
          defaultUnit: i.defaultUnit,
        }))
      : [],
    group: undefined,
  };
}

export function trims(values: Record<string, string>): Record<string, string> {
  if (!isPlainObject(values)) return {};
  const trimValues: Record<string, string> = {};
  Object.keys(values).forEach(key => {
    if (typeof values[key] === 'string') {
      trimValues[key] = values[key].trim();
    } else {
      trimValues[key] = values[key];
    }
  });
  return trimValues;
}

export const radiansToDegrees: Function = radians => (radians * 180) / Math.PI;

export const degreesToRadians: Function = degrees => degrees * (Math.PI / 180);

interface Geo {
  lat: number;
  lon: number;
}
function Area(p0: Geo, p1: Geo, p2: Geo): number {
  let area: number = 0.0;
  area =
    p0.lat * p1.lon +
    p1.lat * p2.lon +
    p2.lat * p0.lon -
    p1.lat * p0.lon -
    p2.lat * p1.lon -
    p0.lat * p2.lon;
  return area / 2;
}

export function geoLocationCenter(points) {
  let sumX = 0;
  let sumY = 0;
  let sumArea = 0;
  let p1 = points[1];
  for (let i = 2; i < points.length; i++) {
    const p2 = points[i];
    const area = Area(points[0], p1, p2);
    sumArea += area;
    sumX += (points[0].lat + p1.lat + p2.lat) * area;
    sumY += (points[0].lon + p1.lon + p2.lon) * area;
    p1 = p2;
  }
  const xx = sumX / sumArea / 3;
  const yy = sumY / sumArea / 3;

  return {
    lat: xx,
    lng: yy,
  };
}

export function randomNum(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}
// ms to date
function update(d) {
  return d > 9 ? d : `0${d}`;
}

// timestamp to YMD
export function changeDateYMD(time) {
  if (!time) return '';
  const d = new Date();
  d.setTime(time);
  const year = d.getFullYear();
  const day = d.getDate();
  const month = d.getMonth() + 1;
  return `${year}-${update(month)}-${update(day)}`;
}

export function arrayDeepFind(array, childrenKey, conditionFunction) {
  let o;
  array.some(function iter(a) {
    if (conditionFunction(a)) {
      o = a;
      return true;
    }
    return Array.isArray(a[childrenKey]) && a[childrenKey].some(iter);
  });
  return o;
}

export function flattenArrayByKey(array, key) {
  if (!array || !array.length || !key) return [];
  const flattenArray: any = [];
  function recursive(_array) {
    _array.forEach(i => {
      if (i[key]) {
        recursive(i[key]);
      } else {
        flattenArray.push(i);
      }
    });
  }
  recursive(array);
  return flattenArray;
}

export function setOptions(menuData) {
  const options = [];
  if (menuData && menuData.length) {
    if (menuData) {
      menuData.forEach(item => {
        options.push({ title: item.name, key: item.code });
      });
    }
  }
  return options;
}

export function setSystemParams(paramData) {
  const params = {};
  let measParams = {};
  paramData.forEach(item => {
    if (item) {
      params[item.name] = item.value;
    }
  });
  const { day, startDay, endDay, measMode } = params;
  if (measMode && measMode === 'lately') {
    measParams = { measMode, day };
  }
  if (measMode && measMode === 'range') {
    measParams = { measMode, startDay, endDay };
  }
  return { params, measParams };
}

/**
 * 建立WS链接相关的方法;jSESSIONID:''
 * @param {*} callBack :回调函数;
 */
export function estabConnectWithWS(callBack) {
  let ws: WebSocket = null;
  const url =
    process.env.NODE_ENV === 'development'
      ? 'localhost:8085/wspn'
      : `${window.location.hostname}:80/wspn`;
  if ('WebSocket' in window) {
    if (window.location.protocol === 'http:') {
      // 注意：使用nginx代理地址时末尾记得加上斜杠"/"。
      ws = new WebSocket(`ws://${url}/websocket/`);
    } else {
      ws = new WebSocket(`wss://${url}/websocket/`);
    }
  }

  ws.onopen = function() {
    console.log('连接上 ws 服务端了');
  };

  ws.onmessage = event => {
    console.log(`接收服务端发过来的消息:${event.data}`);
    const msgJson = JSON.parse(event.data);
    callBack(msgJson);
  };

  ws.onerror = event => {
    console.log(`websocket错误:${event}`);
    callBack('500');
  };

  ws.onclose = event => {
    console.log(`ws 连接关闭了:${event}`);
    callBack('500');
  };
}
