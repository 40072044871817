import { Effect } from 'dva';
import { Reducer } from 'redux';
import services from '@/services';
import { trims } from '@/utils';

export interface CurrentUser {
  avatar?: string;
  name?: string;
  title?: string;
  group?: string;
  signature?: string;
  tags?: {
    key: string;
    label: string;
  }[];
  unreadCount?: number;
}
export interface Preference {
  FirstName: string;
  LastName: string;
  PhoneNumber: string;
  email: string;
  password: string;
  language: string;
  dateFormat: string;
  numberFormat: string;
  fractionalDigits: string;
  numberAlignment: string;
  timezone: string;
  country: string;
}
export interface UserModelState {
  currentUser?: CurrentUser;
  preference: Preference;
  users: Record<string, any>;
  pagination: object;
}

export interface UserModelType {
  namespace: 'user';
  state: UserModelState;
  effects: {
    fetch: Effect;
    fetchCurrent: Effect;
    fetchPreference: Effect;
  };
  reducers: {
    saveCurrentUser: Reducer<UserModelState>;
    changeNotifyCount: Reducer<UserModelState>;
    setUsers: Reducer<UserManageModelState>;
  };
}

const UserModel: UserModelType = {
  namespace: 'user',
  state: {
    pagination: {
      showSizeChanger: true,
      showQuickJumper: true,
      current: 1,
      total: 0,
      pageSize: 10,
    },
    users: {},
    userSettings: {
      dtfrmt: [],
      lcls: {},
      nmrfrmt: {},
      numberAlign: [],
      timeZone: [],
    },
    currentUser: {},
    preference: {
      FirstName: '',
      LastName: '',
      PhoneNumber: '',
      email: '',
      password: '',
      language: '',
      dateFormat: '',
      numberFormat: '',
      fractionalDigits: '',
      numberAlign: '',
      timezone: '',
      country: '',
    },
  },

  effects: {
    *fetch({ payload }, { call, put, select }) {
      const pageSize = yield select(state => state.global.pageSize);
      const res = yield call(services.user.query, {
        ...payload,
        size: pageSize,
      });

      if (res.success) {
        yield put({
          type: 'setUsers',
          payload: res.result,
        });
      }
    },
    *fetchTemplate({ payload }, { call, put }) {
      const res = yield call(services.user.template, payload, { start: 0 });
      if (res.success) {
        yield put({
          type: 'setUsers',
          payload: res.result,
        });
      }
    },
    *add({ payload = {} }, { call }) {
      const res = yield call(services.user.add, trims(payload));
      return res;
    },
    *remove({ payload }, { call }) {
      const res = yield call(services.user.remove, payload);
      return res;
    },
    *update({ payload }, { call }) {
      const res = yield call(services.user.update, payload, { updateMode: 'REPLACE_MODIFIED' });
      return res;
    },
    *fetchUserDetails({ payload }, { call, put }) {
      const res = yield call(services.user.details, payload);
      if (res.success) {
        yield put({
          type: 'saveUserDetails',
          payload: res.result,
        });
      }
    },
    *changePass({ payload }, { call }) {
      const res = yield call(services.user.update, payload, { updateMode: 'REPLACE_MODIFIED' });
      return res;
    },
    *fetchPreference({ payload }, { call, put }) {
      const res = yield call(services.user.preference, payload);
      if (res.success) {
        yield put({
          type: 'savePreference',
          payload: res.result,
        });
      }
    },
    *changePreference({ payload }, { call, put }) {
      yield call(services.user.changePreference, payload);
      // 这里后端应该返回最新修改后端值
      yield put({
        type: 'updatePreference',
        payload,
      });
    },
    *fetchUserSettings(_, { call, put }) {
      const res = yield call(services.user.setting);
      if (res.success) {
        yield put({
          type: 'saveUserSettings',
          payload: res.result,
        });
      }
    },
  },

  reducers: {
    saveUserDetails(state, action) {
      return {
        ...state,
        currentUser: action.payload || {},
      };
    },
    savePreference(state, action) {
      return {
        ...state,
        preference: action.payload || {},
      };
    },
    updatePreference(state, action) {
      return {
        ...state,
        preference: {
          ...state.preference,
          ...(action.payload || {}),
        },
      };
    },
    saveUserSettings(state, action) {
      return {
        ...state,
        userSettings: action.payload || {},
      };
    },
    setUsers(state, { payload }) {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          total: payload.pagination.total,
        },
        users: {
          template: payload.template,
          rows: payload.data,
        },
      };
    },
    changeNotifyCount(
      state = {
        currentUser: {},
      },
      action
    ) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};

export default UserModel;
