export const apis = {
  auth: {
    refreshToken: 'POST auth/refresh',
    login: 'POST auth/login',
    logout: 'POST auth/logout',
  },
  user: {
    query: 'POST sys/account/list', // 获取用户信息分页数据
    add: 'POST sys/account', // 增加用户信息
    update: 'PUT sys/account', // 编辑用户信息
    remove: 'DELETE sys/account/:id', // 删除用户信息
    addOrg: 'POST rbac/accountOrg', // 新增用户组织关联信息数据
    updateOrg: 'PUT rbac/accountOrg', // 根据id更新用户组织关联信息数据
  },
  sys: {
    name: 'sys/param/2', // 获取项目名称接口
    platForm: 'sys/param/1', // 获取项目版本接口
    digitAccuracy: 'sys/dict/type?type=digitAccuracy', // 获取小数位数接口
    volumeUnit: 'sys/dict/type?type=volumeUnit', // 获取流量单位接口
    deleteReason: 'sys/dict/type?type=logReason', // 根据类型查询错误信息
    updateName: 'PUT sys/param', // 修改项目名称接口
    getDictByType: 'sys/dict/type', // 根据类型查询字典信息数据
    dmaParam: 'sys/param', // 查询dma系统参数数据
    param: 'PUT sys/param', // 根据id更新系统参数数据
    batch: 'PUT sys/param/batch', // 根据多个id更新系统参数数据
    getParam: 'sys/param', // 查询系统参数数据
    dictAll: 'sys/dict/all', // 查询所有字典项
    license: 'sys/param/license', // 获取license
    rootName: 'sys/param/rootName', // 查询rootName
    updateRootName: 'PUT sys/param/rootName', // 更新rootName
  },
  role: {
    createRole: 'POST rbac/role', // 新增角色信息
    updateRole: 'PUT rbac/role', // 修改角色信息
    role: 'rbac/role/:id', // 根据id获取角色信息
    roles: 'POST rbac/role/list', // 查询角色信息分页数据
    deleteRole: 'DELETE rbac/role/:id', // 根据id删除角色信息
    deleteRoles: 'DELETE rbac/role', // 批量删除角色信息
    getRoleList: 'rbac/role/list', // 查询角色列表数组
  },
  resource: {
    permissions: 'rbac/resource/permission', // 获取所有权限
    permission: 'rbac/resource/permission?roleId=:roleId', // 根据id查询资源信息数据
  },
  organization: {
    details: '/core/org/:id',
    tree: 'core/org/tree', // 获取组织树
    customers: 'POST core/customer/list', // 获取客户信息
    orgList: 'POST core/org/list', // 查询组织信息分页数据
    addOrganization: 'POST core/org', // 新增组织信息数据
    updateOrganization: 'PUT core/org', // 根据id更新组织信息数据
    deleteOrganization: 'DELETE core/org', // 批量删除组织信息数据
    orgReaInfo: 'POST core/org/orgReaInfo', // 查询是否有关联的子节点、客户和仪表信息
    deleteOrgs: 'DELETE core/org', // 批量删除组织、客户、仪表信息数据
    orgNameStrFormat: 'core/org/strFormat', // 拼装组织流详情信息
    expOrg: 'POST core/org/excels', // 导出组织信息
  },
  customer: {
    add: 'POST core/customer', // 新增客户信息
    update: 'PUT core/customer', // 编辑客户信息
    getCustomById: 'core/customer/:id', // 根据id查询客户信息数据
    deleteCustomer: 'DELETE core/customer/id=:id?reason=:reason', // 删除客户信息
    deleteCustomers: 'DELETE core/customer', // 批量删除客户信息
    expCustomer: 'POST core/customer/excels', // 导出客户信息
    importTemplate: 'POST /core/customer/customerAndDeviceExcels', // 批量导入客户基本信息
    exportTemplate: 'POST /core/customer/excelsTemplate', // 导出客户模板
    queryErrorList: 'POST /core/customer/list', // 查询异常客户信息分页数据
  },
  device: {
    queryConcentrator: 'POST core/concentrator/list', // 查询集中器管理分页数据
    addConcentrator: 'POST core/concentrator', // 新增集中器管理数据
    editConcentrator: 'PUT core/concentrator', // 根据id更新集中器管理数据
    deleteConcentrators: 'DELETE core/concentrator', // 批量删除集中器管理数据
    queryConcentratorById: 'core/concentrator/id=:id', // 根据id查询集中器管理数据
    addMeter: 'POST core/meter', // 新增仪表信息数据
    path: 'POST core/meter/path', // 添加地图坐标和安装图片
    updateMeter: 'PUT core/meter', // 根据id更新仪表信息数据
    deleteMeters: 'DELETE core/meter', // 批量删除仪表信息数据
    meterList: 'POST core/meter/list', // 查询仪表信息分页数据  换成新接口
    details: '/core/meter/:id',
    meterDiameters: 'sys/dict/type?type=meterDiameter', // 根据类型查询口径字典信息数据
    meterScenes: 'sys/dict/type?type=meterScene', // 根据类型查询计量场景字典信息数据
    meterMediums: 'sys/dict/type?type=meterMedium', // 根据类型查询计量介质字典信息数据
    // getCorrelationConcentrator: 'core/meter/relation/concentrator?meterId=:meterId', // 根据集中器id查询集中器与仪表关联信息表数据  已废弃
    queryConcentratorMeterInfo: 'core/meter/queryConcentratorMeterInfo', // 根据仪表id查询仪表与集中器关联信息数据   有用   判断仪表是否关联集中器
    addCustomerMeter: 'POST core/customer/relation/meter', // 更新和添加客户仪表关联信息
    // queryCustomerMeterById: 'core/meter/relation/customer/:customerId', // 通过客户id查询已关联的仪表信息
    queryCustomerMeter: 'POST core/meter/relation/customer/list', // 通过组织ID查询所有可关联的仪表
    deleteCustomerMeter: 'DELETE core/customer/relation/meter', // 通过客户id删除已关联的仪表
    // queryConcentratorRelation: 'core/meter/relation/concentrator/:meterId', // 根据仪表id查询仪表与集中器关联信息数据   已废弃
    addConcentratorMeter: 'PUT /core/meter/relation', // 新增集中器与仪表关联信息表数据
    delConcentratorMeter: 'DELETE core/concentrator/ConcentratorMeter', // 删除集中器与仪表的关联关系
    // meterRelationCentrator: 'POST core/concentrator/MeterCentrator', // 新增仪表与集中器关联信息表数据 已废弃
    meterRelationCentrator: 'PUT /core/meter/relation', // 添加和更新仪表集中器关联信息
    queryConcentratorMeter: 'POST core/meter/listNew', // 查询关联组织且没有关联的仪表信息分页数据
  },
  supply: {
    queryMeterListByMeterId: 'core/meter/meterList?meterNumber=:meterNumber', // 根据meter_number查询仪表数据以及仪表关联的组织
    queryMeasValListByMeterId: 'POST core/measVal/list', // 抄表数据详情页面查询分页数据
  },
  measVal: {
    dataList: 'POST /core/measVal/latestList', // 抄表数据列表查询分页数据
    excels: 'POST /core/measVal/excels', // 导出最新抄表列表数据
    meterMeasValList: 'POST /core/measVal/getMeasValListByMeterId',
    list: 'POST /core/measVal/list', // 抄表数据分页列表查询
    latestMeasValExcels: 'POST /core/measVal/latestMeasValExcels', // 根据组织id导出抄表信息
    measValExcels: 'POST /core/measVal/measValExcels', // 根据仪表id导出抄表信息
    addMeasVal: 'POST /core/measVal', // 新增抄表数据
    delMeasVal: 'DELETE /core/measVal', // 删除抄表数据
    meterReadingRate: '/core/measVal/meterReadingRate', // 抄表率查询
    withoutLatestMeasVal: 'POST /core/measVal/meter/withoutLatestMeasVal', // 查询没有最新抄表的仪表
    alarm: '/core/alarm', // 查询表端报警
    differenceSet: 'POST /core/measVal/latestList/differenceSet', // 查询没有最新抄表仪表的最近一条抄表数据
    acqInfoStatistic: '/core/measVal/acqInfoStatistic', // 抄表方式
  },
  concentrator: {
    details: '/core/concentrator/:id',
    concentratorInfoStatistic: '/core/concentrator/concentratorInfoStatistic', // 集中器信息
  },
  consumption: {
    waterConsumptionList: 'POST /core/waterConsumption/list', // 查询消耗管理分页数据
    consumptionList: 'POST /core/waterConsumption/consumptionList', // 查询详情页消耗管理列表数据
    consumptionChart: 'POST /core/waterConsumption/consumptionChart', // 查询详情页消耗管理列表数据
  },
  waterDma: {
    waterDmaTree: '/dma/waterDma/tree', // 获取分区管理树
    dmaParam: '/dma/waterDma/dmaParam', // 获取分区阈值参数
    meterList: 'POST /dma/waterDma/meterList', // 根据分区id查询关联仪表数据
    meterFailList: 'POST /dma/waterDma/meterFailList', // 统计信息，根据分区id查询抄表失败的仪表数据
    statistics: '/dma/waterDma/statistics', // 统计信息-仪表，居民，非居民，获取某一个分区仪表数 户数 客户数
    waterDmaParam: 'PUT /dma/waterDma/dma', // 向导第一步更新分区信息及个别阈值信息
    abnormalMeterList: 'POST /dma/waterDma/abnormalMeterList', // 统计信息-异常仪表列表， 已删除仪表列表
    metersDma: 'DELETE /dma/waterDma/metersDma', // 没有关联分区的仪表信息
    meterReadingRate: '/dma/waterDma/meterReadingRate', // 统计信息，获取某个分区的抄表率
    dmaName: '/dma/waterDma/dmaName', // 校验分区名称是否重复
    alarmCount: '/dma/waterDma/alarmCount', // 统计信息-分区报警，获取分区被删除仪表的个数
    noDmaMeters: 'POST /dma/waterDma/noDmaMeters', // 编辑/新增分区第二步或第三步、查询没有关联分区的仪表信息
    dmaAndMeter: 'POST /dma/waterDma/dmaAndMeter', // 保存/更新分区信息以及该分区关联的仪表信息
    queryDmaName: '/dma/waterDma/:id', // 编辑/新增分区第一步获取某个分区的基本信息
    deleteDma: 'DELETE /dma/waterDma', // 删除分区
    allDmaAndMeterInfo: '/dma/waterDma/allDmaAndMeterInfo', // 编辑/新增分区第二步，查询分区信息和仪表信息
    dmaMap: 'PUT /dma/waterDma/dmaMap', // 分区管理第4步，创建地图信息
    checkMeters: 'POST /dma/waterDma/checkMeters', // 编辑/新增分区第二步，仅第二步，新增修改仪表前先检查分区仪表数据
    consumeMeterInfo: '/dma/waterDma/consumeMeterInfo', // 编辑/新增分区第三步创建子分区选择进水总表时，查询可用的仪表
    analysis: '/dma/waterDma/analysis',
  },
  dmaPlugin: {
    queryDailyLeakageRateTopFive: '/dmaPlugin/inputConsumeDiffs/queryDailyLeakageRateTopFive', // 用来查询日漏损率top5的小区
    queryLeakageRateTopFive: '/dmaPlugin/inputConsumeDiffs/queryLeakageRateTopFive', // 用来查询月漏损率top5的小区
    dmaDayOverview: '/dmaPlugin/inputConsumeDiffs/dmaDayOverview', // 查询总分区日概览
    dmaMonthOverview: '/dmaPlugin/inputConsumeDiffs/dmaMonthOverview', // 查询总分区月概览
    nightMinimumFlows: '/dmaPlugin/nightMinimumFlows/echart', // 通过总分区主键查找近60天的夜间小流，包括所有子分区
    allBalance: '/dmaPlugin/monthReport/allBalance', // 查询所有分区水平衡表数据
    hourlyOverall: '/dmaPlugin/inputConsumeDiffs/echart/overall', // 通过总分区主键查找近一年的日总分表差/某天的时分表差（小时漏损曲线数据）
    balance: '/dmaPlugin/monthReport/balance', // 通过分区id月份查询水平衡表数据
    topology: '/dmaPlugin/inputConsumeDiffs/dailyTopology', // 查询某个分区的日/月拓扑图
    overall: '/dmaPlugin/inputConsumeDiffs/echartAllData/overall', // 通过总分区主键查找近两年天的全部数据集合（夜间小流和净夜间小流）（和所有叶子节点的比较）
    replaceMeter: 'POST /dmaPlugin/waterDmaMeters/replaceMeter', // 根据id更新仪表的id
    checkDeletedMeter: 'POST /dmaPlugin/waterDmaMeters/checkDeletedMeter', // 删除异常仪表前，检查仪表是否为某分区的唯一进水总表
    dmaMeter: 'DELETE /dmaPlugin/waterDmaMeters/dmaMeter', // 删除分区与表之间的关联关系
    alarmList: '/dma/alarm/list', // 查询分区报警分页数据
  },
  alarm: {
    withAlarm: 'POST /core/alarm/latestList/withAlarm', // 分页查询具有报警信息的最新抄表数据。
    singleMeterWithAlarm: 'POST /core/alarm/list/singleMeterWithAlarm', // 通过仪表编号查询指定时间范围内具有报警的抄表数据
    list: 'POST /core/alarm/list', // 查询报警规则
    add: 'POST /core/alarm', // 添加报警规则
    edit: 'PUT /core/alarm', // 编辑报警规则
    del: 'DELETE /core/alarm', // 删除报警规则
    alarmInfoStatistic: '/core/alarm/alarmInfoStatistic', // 报警信息
  },
  statistic: {
    dmaInfoStatistic: 'statistic/dmaInfoStatistic', // 计算 总分区的 分区总数+漏损总量+用水总量+供水总量+漏损率
    customerInfoStatistic: 'statistic/customerInfoStatistic', // 客户总数+异常客户数据
    dmaLeakageStatistic: '/statistic/dmaLeakageStatistic', // 计算 总分区的 各分区漏损情况
    leakageStatistic: '/statistic/leakageStatistic', // 计算 最近12个月的总的分区漏损情况
    measValCount: '/statistic/measValCount', // 查询抄表数据总量
    meterAndMeasValStatistic: '/statistic/meterAndMeasValStatistic',
    datavMeasVal: '/datav/measVal',
    measValAlarm: '/datav/measValAlarm',
    measValRateChart: '/datav/measValRateChart',
    waterMonthly: '/datav/consumption/water/monthly',
  },
  license: {
    info: 'POST /license/info', // 通过账号和密码获取license信息
    license: 'PUT /license', // 在线更新license
  },
};
// 测试自动构建 139.9.118.71 测试1234567
// export const apiPrefix =
//   process.env.NODE_ENV === 'development' ? 'http://139.9.118.71:8085' : `http://139.9.118.71:8085`;
// test

export const apiPrefix =
  process.env.NODE_ENV === 'development'
    ? 'http://forworld.forworld.cn/wspn'
    : `http://${window.location.hostname}:80/wspn`;

// export const apiPrefix = 'http://localhost:8085/wspn';

export const mapApi = 'https://nominatim.openstreetmap.org/search.php?';

export const mapLableApi = 'https://nominatim.openstreetmap.org/reverse.php?';

export const timezoneApi = 'http://api.geonames.org/timezoneJSON?';

export const osmUrl =
  'http://webrd0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}';

export const osmOption = {
  maxZoom: 18,
  attribution: '&copy; 高德地图',
  subdomains: '1234',
};

export const bigScreen = 'http://bigscreen.forworld.cn';
