import { AnyAction, Reducer } from 'redux';
import { EffectsCommandMap } from 'dva';
import { routerRedux } from 'dva/router';
import { stringify } from 'qs';
import services from '@/services';
import { getPageQuery, storage, trims, setSystemParams } from '@/utils';
import { setAccessToken, setRefreshToken } from '@/utils/token';
import {
  emptyGlobalPermissions,
  PERMISSION_STORAGE_KEY,
  checkPermission,
} from '@/utils/permission';
import routes from '../../config/routes';
import { setProjectInfo, getProjectInfo } from '@/utils/projectInfo';

export interface StateType {
  status?: 'ok' | 'error';
  type?: string;
}

export type Effect = (
  action: AnyAction,
  effects: EffectsCommandMap & { select: <T>(func: (state: StateType) => T) => T }
) => void;

export interface ModelType {
  namespace: string;
  state: StateType;
  effects: {
    login: Effect;
    logout: Effect;
    toLicenseView: Effect;
    toLoginView: Effect;
  };
  reducers: {
    changeLoginStatus: Reducer<StateType>;
  };
}

const Model: ModelType = {
  namespace: 'auth',

  state: {
    status: undefined,
  },

  effects: {
    *login({ payload, callback }, { call, put }) {
      const res = yield call(services.auth.login, trims(payload));
      yield put({
        type: 'changeLoginStatus',
        payload: res,
      });
      // Login successfully
      if (res.success) {
        if (res.result) {
          setAccessToken(res.result.accessToken);
          setRefreshToken(res.result.refreshToken);
          storage.set('userInfo', res.result);
          storage.set(PERMISSION_STORAGE_KEY, res.result.resourceList);
          // 存储项目信息
          setProjectInfo(res.result.projectInfo);
          // 登录成功将字典表内容存储
          const dictAllRes = yield call(services.sys.dictAll);
          if (dictAllRes.success) {
            storage.set('dictAll', dictAllRes.result);
          }
          // 登录成功将系统参数存储
          const systemRes = yield call(services.sys.getParam, { param: 'system' });
          if (systemRes.success) {
            const { measParams, params } = setSystemParams(systemRes.result);
            storage.set('measParams', measParams);
            storage.set('sysParams', params);
          }

          // 登录成功将报警信息存储
          const alarmMeasValRes = yield call(services.measVal.alarm);
          if (alarmMeasValRes.success) {
            storage.set('alarmMeasVal', alarmMeasValRes.result);
          }

          // 登录成功后根据用户已有权限设置登录展示页面
          const paths = [];
          const getPath = data => {
            data.forEach(item => {
              if (item.authKey && checkPermission(item.authKey) && !item.routes) {
                paths.push(item.path);
              }
              if (item.routes && item.routes.length > 0) {
                getPath(item.routes);
              }
            });
          };
          getPath(routes);
          yield put(routerRedux.replace(paths.length > 0 ? paths[0] : '/'));
        }
      }
      if (callback) callback(res);
    },
    *logout({ payload }, { call, put }) {
      emptyGlobalPermissions();
      const res = yield call(services.auth.logout, payload);
      const { redirect } = getPageQuery();

      const isRemember: any = storage.get('wspn_username');

      const projectInfo = getProjectInfo();
      storage.clear();
      setProjectInfo(projectInfo);
      storage.set('wspn_username', isRemember);
      yield put({
        type: 'changeLoginStatus',
        payload: res,
      });
      // Login successfully
      if (window.location.pathname !== '/user/login' && !redirect) {
        yield put(
          routerRedux.replace({
            pathname: '/login',
            search: stringify({
              redirect: window.location.href,
            }),
          })
        );
      }
    },
    *toLicenseView(_, { put }) {
      if (window.location.pathname !== '/user/license') {
        yield put(
          routerRedux.replace({
            pathname: '/license',
            search: stringify({
              redirect: window.location.href,
            }),
          })
        );
      }
    },
    *toLoginView(_, { put }) {
      if (window.location.pathname !== '/user/license') {
        yield put(
          routerRedux.replace({
            pathname: '/login',
            search: stringify({
              redirect: window.location.href,
            }),
          })
        );
      }
    },
  },

  reducers: {
    changeLoginStatus(state, { payload }) {
      return {
        ...state,
        status: payload.status,
        type: payload.type,
      };
    },
  },
};

export default Model;
