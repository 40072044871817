export type $StrConstant =
  | 'WHITE_SPACE'
  | 'EMPTY'
  | 'COMMA'
  | 'POINT'
  | 'OPENING_BRACKET'
  | 'CLOSING_BRACKET';

export const STR_CONSTANTS: $StrConstant = {
  WHITE_SPACE: ' ',
  EMPTY: '',
  COMMA: ',',
  POINT: '.',
  OPENING_BRACKET: '(',
  CLOSING_BRACKET: ')',
};

export type $TableTranslationTypes =
  | 'NONE'
  | 'TEXT'
  | 'IMAGE'
  | 'MULTI_IMAGE'
  | 'NUMBER'
  | 'TOUR'
  | 'NUMBER_KEEP_PRECISION'
  | 'DATE'
  | 'CUSTOM';

export const TABLE_TRANSLATION_TYPES: $TableTranslationTypes = {
  NONE: 'NONE',
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  MULTI_IMAGE: 'MULTI_IMAGE',
  NUMBER: 'NUMBER',
  NUMBER_KEEP_PRECISION: 'NUMBER_KEEP_PRECISION',
  DATE: 'DATE',
  CUSTOM: 'CUSTOM',
};

export type $TreeNodeTitleTypes =
  | '仪表总数'
  | '有最新抄表'
  | '无最新抄表'
  | '有消耗仪表'
  | '无消耗仪表'
  | '报警仪表'
  | '客户数量'
  | '异常客户数量'
  | '集中器数量';

export const TREE_NODE_TITLE_TYPES: $TreeNodeTitleTypes = {
  METER: '仪表总数',
  LATEST: '有最新抄表',
  NOLATEST: '无最新抄表',
  CONSUME: '有消耗仪表',
  NOCONSUME: '无消耗仪表',
  ALARM: '报警仪表',
  CUSTOMER: '客户数量',
  NONOMALCUS: '异常客户数量',
  CONCENTRATOR: '集中器数量',
};

export const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

export const TREE_NODE_BOOT = '1';
export const TREE_NODE_ALL = 0;

export const emptyString = '--';

export const treeLayout = {
  xs: 11,
  sm: 9,
  md: 7,
  lg: 5,
  xl: 4,
};

export const tableLayout = {
  xs: 13,
  sm: 15,
  md: 17,
  lg: 19,
  xl: 20,
};
