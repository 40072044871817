import { Reducer } from 'redux';
import { Subscription } from 'dva';
import { Effect } from '@/models/connect.d';
import services from '@/services';
import { storage } from '@/utils';

export interface DeviceManagementState {
  concentratorDataSource: [];
  concentratorPagination: object;
  concentratorTreeData: [];
  meterTableData: [];
  meterTreeData: [];
  assMeterData: [];
  meterDetailTabs: [];
}

export interface DeviceManagementType {
  namespace: 'deviceManagement';
  state: DeviceManagementState;
  effects: {
    queryConcentrator: Effect;
    addConcentrator: Effect;
    editConcentrator: Effect;
    deleteConcentrator: Effect;
    deleteConcentrators: Effect;
    queryConcentratorById: Effect;
    queryConcentratorTreeData: Effect;
  };
  reducers: {
    device: Reducer<DeviceManagementState>;
  };
  subscriptions: { setup: Subscription };
}

export default {
  namespace: 'deviceManagement',
  state: {
    concentratorDataSource: [],
    concentratorTreeData: [],
    concentratorPagination: {
      current: 1,
      total: 0,
      pageSize: 10,
    },
    meterTableData: [],
    meterTreeData: [],
    assMeterData: [],
    meterDetailTabs: [],
  },
  effects: {
    *fetchConcentratorTreeData({ payload }, { call, put }) {
      const res = yield call(services.organization.tree, payload);
      if (res.success) {
        yield put({
          type: 'getConcentratorTreeData',
          payload: res.result,
        });
      }
      return res;
    },
    *fetchConcentrator({ payload }, { call, put }) {
      const res = yield call(services.device.queryConcentrator, payload.body, payload.query);
      if (res.success) {
        yield put({
          type: 'getConcentrator',
          payload: res.result,
        });
      }
      return res;
    },
    *addConcentrator({ payload }, { call, put }) {
      const res = yield call(services.device.addConcentrator, payload);
      if (res.success) {
        yield put({
          type: 'createConcentrator',
          payload: res.result,
        });
      }
      return res;
    },
    *updateConcentrator({ payload }, { call, put }) {
      const res = yield call(services.device.editConcentrator, payload);
      if (res.success) {
        yield put({
          type: 'editConcentrator',
          payload: res.result,
        });
      }
      return res;
    },
    *deleteConcentrators({ payload }, { call, put }) {
      const res = yield call(services.device.deleteConcentrators, payload.ids, payload.query);
      if (res.success) {
        yield put({
          type: 'removeConcentrators',
          payload: res.result,
        });
      }
      return res;
    },
    *queryConcentratorMeter({ payload }, { call, put }) {
      const res = yield call(services.device.meterList, payload.body, payload.query);
      if (res.success) {
        yield put({
          type: 'getConcentratorMeter',
          payload: res.result,
        });
      }
      return res;
    },
    *addMeter({ payload }, { call, put }) {
      const res = yield call(services.device.addMeter, payload);
      if (res.success) {
        yield put({
          type: 'add_Meter',
          payload: res.result,
        });
      }
      return res;
    },
    *addPath({ payload }, { call, put }) {
      const res = yield call(services.device.path, payload);
      if (res.success) {
        yield put({
          type: 'add_path',
          payload: res.result,
        });
      }
      return res;
    },
    *meterDiameters({ payload }, { call, put }) {
      const res = yield call(services.device.meterDiameters, payload);
      if (res.success) {
        yield put({
          type: 'getMeterDiameters',
          payload: res.result,
        });
      }
      return res;
    },
    *meterScenes({ payload }, { call, put }) {
      const res = yield call(services.device.meterScenes, payload);
      if (res.success) {
        yield put({
          type: 'getMeterScenes',
          payload: res.result,
        });
      }
      return res;
    },
    *meterMediums({ payload }, { call, put }) {
      const res = yield call(services.device.meterMediums, payload);
      if (res.success) {
        yield put({
          type: 'getMmeterMediums',
          payload: res.result,
        });
      }
      return res;
    },
    *updateMeter({ payload }, { call, put }) {
      const res = yield call(services.device.updateMeter, payload);
      if (res.success) {
        yield put({
          type: 'update_Meter',
          payload: res.result,
        });
      }
      return res;
    },
    *deleteMeters({ payload }, { call, put }) {
      const res = yield call(services.device.deleteMeters, {}, payload);
      if (res.success) {
        yield put({
          type: 'delete_Meters',
          payload: res.result,
        });
      }
      return res;
    },
    *meterTreeData({ payload }, { call, put }) {
      const res = yield call(services.organization.tree, payload);
      if (res.success) {
        yield put({
          type: 'getMeterTreeData',
          payload: res.result,
        });
      }
      return res;
    },
    *queryConcentratorMeterInfo({ payload }, { call, put }) {
      const res = yield call(services.device.queryConcentratorMeterInfo, {}, payload);
      if (res.success) {
        yield put({
          type: 'getConcentratorMeterInfo',
          payload: res.result,
        });
      }
      return res;
    },
    *addConcentratorMeter({ payload }, { call, put }) {
      const res = yield call(
        services.device.meterRelationCentrator,
        payload.meterIds,
        payload.query
      );
      if (res.success) {
        yield put({
          type: 'add_ConcentratorMeter',
          payload: res.result,
        });
      }
      return res;
    },
    *delConcentratorMeter({ payload }, { call, put }) {
      const res = yield call(services.device.delConcentratorMeter, {}, payload);
      if (res.success) {
        yield put({
          type: 'del_ConcentratorMeter',
          payload: res.result,
        });
      }
      return res;
    },
    *meterRelationCentrator({ payload }, { call, put }) {
      const res = yield call(services.device.meterRelationCentrator, payload.body, payload.query);
      if (res.success) {
        yield put({
          type: 'add_MeterCentrator',
          payload: res.result,
        });
      }
      return res;
    },
    *fetchMeterCorrelationConcentrator({ payload }, { call, put }) {
      const res = yield call(services.device.queryConcentrator, payload.body, payload.query);
      if (res.success) {
        yield put({
          type: 'getMeterCorrelationConcentrator',
          payload: res.result,
        });
      }
      return res;
    },
    *deleteCustomerMeter({ payload }, { call, put }) {
      const res = yield call(services.device.deleteCustomerMeter, payload);
      if (res.success) {
        yield put({
          type: 'delete_CustomerMeter',
          payload: res.result,
        });
      }
      return res;
    },
    *onChangeMeterDetailTab({ payload }, { put }) {
      if (payload) {
        yield put({
          type: 'changeMeterTabs',
          payload,
        });
      }
    },
    *onCloseMeterDetailTab({ payload }, { put }) {
      if (payload) {
        yield put({
          type: 'closeMeterTab',
          payload,
        });
      }
    },
  },
  reducers: {
    getConcentrator(state, { payload }) {
      return {
        ...state,
        concentratorDataSource: payload.data || [],
        concentratorPagination: {
          ...state.concentratorPagination,
          total: payload.pagination.total,
          current: payload.pagination.current,
          pageSize: payload.pagination.size,
        },
      };
    },
    getConcentratorTreeData(state, { payload }) {
      return {
        ...state,
        concentratorTreeData: payload,
      };
    },
    createConcentrator(state) {
      return {
        ...state,
      };
    },
    editConcentrator(state) {
      return {
        ...state,
      };
    },
    removeConcentrators(state) {
      return {
        ...state,
      };
    },
    getConcentratorMeter(state, { payload }) {
      return {
        ...state,
        assMeterData: payload.data,
      };
    },
    add_Meter(state) {
      return {
        ...state,
      };
    },
    getMeterDiameters(state) {
      return {
        ...state,
      };
    },
    getMeterScenes(state) {
      return {
        ...state,
      };
    },
    getMmeterMediums(state) {
      return {
        ...state,
      };
    },
    update_Meter(state) {
      return {
        ...state,
      };
    },
    delete_Meters(state) {
      return {
        ...state,
      };
    },
    getCorrelation_Concentrator(state) {
      return {
        ...state,
      };
    },
    getMeterTreeData(state, { payload }) {
      return {
        ...state,
        meterTreeData: payload,
      };
    },
    getConcentratorMeterInfo(state) {
      return {
        ...state,
      };
    },
    add_ConcentratorMeter(state) {
      return {
        ...state,
      };
    },
    del_ConcentratorMeter(state) {
      return {
        ...state,
      };
    },
    add_MeterCentrator(state) {
      return {
        ...state,
      };
    },
    getMeterCorrelationConcentrator(state) {
      return {
        ...state,
      };
    },
    delete_CustomerMeter(state) {
      return {
        ...state,
      };
    },
    changeMeterTabs(state, { payload }) {
      const meterTabs = storage.get('meterDetailTabs') ? storage.get('meterDetailTabs') : [];
      storage.set('meterDetailTabs', Array.from(arrReducer([...meterTabs, ...payload])));
      return {
        ...state,
        meterDetailTabs: Array.from(arrReducer([...state.meterDetailTabs, ...payload])),
      };
    },
    closeMeterTab(state, { payload }) {
      const meterTabs = storage.get('meterDetailTabs') ? storage.get('meterDetailTabs') : [];
      const newMeterTabs = [...meterTabs];
      let i = -1;
      let x = 0;
      newMeterTabs.forEach((item, index) => {
        if (item.meterId === payload.meterId) {
          i = index;
          x = 1;
        }
      });
      newMeterTabs.splice(i, x);
      storage.set('meterDetailTabs', newMeterTabs);
      return {
        ...state,
        meterDetailTabs: meterTabs,
      };
    },
  },
  subscriptions: {},
};

// 数组去重
function arrReducer(arr) {
  let arrTemp = arr;
  const obj = {};
  arrTemp = arrTemp.reduce((cur, next) => {
    // eslint-disable-next-line no-unused-expressions
    obj[next.meterId] ? '' : (obj[next.meterId] = true && cur.push(next));
    return cur;
  }, []);
  return arrTemp;
}
