import React from 'react';
import router from 'umi/router';
import { storage } from '@/utils';

export const PERMISSION_KEY = 'permissionKey';

let PERMISSIONS_ENABLE = true;

export const PERMISSION_STORAGE_KEY = 'userPermissions';

let globalPermissions: any[] = [];

export function setPermissionsEnable(enable) {
  PERMISSIONS_ENABLE = enable;
}

export function emptyGlobalPermissions() {
  globalPermissions = [];
}

const permissionList = [
  'DASHBOARD_VIEW', // '首页', '首页(一级菜单)'

  'SUPPLY_VIEW', // '供水管理', '供水管理(一级菜单)'

  'SUPPLY_MEAS_NEW', // '最新抄表', '供水管理-最新抄表(二级菜单)'
  'SUPPLY_MEAS_NEW_EXPORT', // '导出', '供水管理-最新抄表-最新抄表导出'
  'SUPPLY_MEAS_NEW_EXPORT2', // '定制导出', '供水管理-最新抄表-最新抄表定制导出'
  'SUPPLY_MEAS_NEW_VIEW', // '查看', '供水管理-最新抄表-最新抄表查看'
  'SUPPLY_MEAS_NEW_ADD', // '新增', '供水管理-最新抄表-新增抄表数据'
  'SUPPLY_MEAS_NEW_DELETE', // '删除', '供水管理-最新抄表-删除抄表数据'

  'SUPPLY_MEAS_HISTORY', // '历史抄表', '供水管理-历史抄表(二级菜单)'
  'SUPPLY_MEAS_HISTORY_VIEW', // '查看', '供水管理-历史抄表-历史抄表查询'

  'SUPPLY_CONSUMPTION', // '消耗管理', '供水管理-消耗管理(二级菜单)'
  'SUPPLY_CONSUMPTION_VIEW', // '查看', '供水管理-消耗管理-消耗管理查看'
  'SUPPLY_CONSUMPTION_EXPORT', // '导出', '供水管理-消耗管理-消耗管理导出'

  'DMA_VIEW', // '分区管理', '分区管理(一级菜单)'

  'DMA_MANAGEMENT', // '分区管理', '分区管理-分区管理(二级菜单)'
  'DMA_MANAGEMENT_VIEW', // '查看', '分区管理-分区管理-分区管理查看'
  'DMA_MANAGEMENT_ADD', // '新增', '分区管理-分区管理-新增分区'
  'DMA_MANAGEMENT_DELETE', // '删除', '分区管理-分区管理-删除分区'
  'DMA_MANAGEMENT_EDIT', // '编辑', '分区管理-分区管理-编辑分区'
  'DMA_MANAGEMENT_METER_DELETE', // '删除', '分区管理-分区管理-异常仪表-删除仪表'
  'DMA_MANAGEMENT_METER_REPLACE', // '替换', '分区管理-分区管理-异常仪表-替换仪表'

  'DMA_LEAKAGE', // '漏损分析', '分区管理-漏损分析(二级菜单)'
  'DMA_LEAKAGE_VIEW', // '查看', '分区管理-漏损分析-查看'

  'ALARM_VIEW', // '报警管理', '报警管理(一级菜单)'

  'ALARM_METER', // '报警仪表', '报警管理-报警仪表(二级菜单)'
  'ALARM_METER_VIEW', // '查看', '报警管理-报警仪表-查看'

  'ALARM_HISTORY', // '历史报警', '报警管理-历史报警(二级菜单)'
  'ALARM_HISTORY_VIEW', // '查看', '报警管理-历史报警-查看'

  'ALARM_RULE', // '报警规则', '报警管理-报警规则(二级菜单)'
  'ALARM_RULE_VIEW', // '查看', '报警管理-报警规则-查看'
  'ALARM_RULE_ADD', // '新增', '报警管理-报警规则-新增'
  'ALARM_RULE_EDIT', // '编辑', '报警管理-报警规则-编辑'
  'ALARM_RULE_DELETE', // '删除', '报警管理-报警规则-删除'

  'CUSTOMER_VIEW', // '客户管理', '客户管理(一级菜单)'

  'CUSTOMER_ORGANIZATION', // '组织管理', '客户管理-组织管理(二级菜单)'
  'CUSTOMER_ORGANIZATION_VIEW', // '查看', '客户管理-组织管理-查看'
  'CUSTOMER_ORGANIZATION_ADD', // '新增', '客户管理-组织管理-新增'
  'CUSTOMER_ORGANIZATION_EDIT', // '编辑', '客户管理-组织管理-编辑'
  'CUSTOMER_ORGANIZATION_DELETE', // '删除', '客户管理-组织管理-删除'
  'CUSTOMER_ORGANIZATION_EXPORT', // '导出', '客户管理-组织管理-导出'

  'CUSTOMER_INFORMATION', // '客户信息', '客户管理-客户信息(二级菜单)'
  'CUSTOMER_INFORMATION_VIEW', // '查看', '客户管理-客户信息-查看'
  'CUSTOMER_INFORMATION_ADD', // '新增', '客户管理-客户信息-新增'
  'CUSTOMER_INFORMATION_EDIT', // '编辑', '客户管理-客户信息-编辑'
  'CUSTOMER_INFORMATION_DELETE', // '删除', '客户管理-客户信息-删除'
  'CUSTOMER_INFORMATION_EXPORT', // '导出', '客户管理-客户信息-导出'
  'CUSTOMER_INFORMATION_DEVICE_ASSOCIATED', // '关联设备', '客户管理-客户信息-关联设备'
  'CUSTOMER_INFORMATION_TEMPLATE_IMPORT', // '导入数据', '客户管理-客户信息-导入数据'

  'DEVICE_VIEW', // '设备管理', '设备管理(一级菜单)'

  'DEVICE_METER', // '仪表管理', '设备管理-仪表管理(二级菜单)'
  'DEVICE_METER_VIEW', // '查看', '设备管理-仪表管理-查看'
  'DEVICE_METER_ADD', // '新增', '设备管理-仪表管理-新增'
  'DEVICE_METER_EDIT', // '编辑', '设备管理-仪表管理-编辑'
  'DEVICE_METER_DELETE', // '删除', '设备管理-仪表管理-删除'
  'DEVICE_METER_EXPORT', // '导出', '设备管理-仪表管理-导出'
  'DEVICE_METER_CONCENTRATOR_ASSOCIATED', // '关联集中器', '设备管理-仪表管理-关联集中器'

  'DEVICE_CONCENTRATOR', // '集中器管理', '设备管理-集中器管理(二级菜单)'
  'DEVICE_CONCENTRATOR_VIEW', // '查看', '设备管理-集中器管理-查看'
  'DEVICE_CONCENTRATOR_ADD', // '新增', '设备管理-集中器管理-新增'
  'DEVICE_CONCENTRATOR_EDIT', // '编辑', '设备管理-集中器管理-编辑'
  'DEVICE_CONCENTRATOR_DELETE', // '删除', '设备管理-集中器管理-删除'
  'DEVICE_CONCENTRATOR_EXPORT', // '导出', '设备管理-集中器管理-导出'
  'DEVICE_CONCENTRATOR_METER_ASSOCIATED', // '关联设备', '设备管理-集中器管理-关联设备'

  'SYSTEM_VIEW', // '系统管理', '系统管理(一级菜单)'

  'SYSTEM_PARAMETER', // '系统参数', '系统管理-系统参数(二级菜单)'
  'SYSTEM_PARAMETER_SYSTEM', // '系统参数', '系统管理-系统参数-系统参数(三级菜单)'
  'SYSTEM_PARAMETER_DMA', // '分区计量参数', '系统管理-系统参数-分区计量参数(三级菜单)'
  'SYSTEM_PARAMETER_SYSTEM_VIEW', // '查看', '系统管理-系统参数-系统参数-查看'
  'SYSTEM_PARAMETER_SYSTEM_EDIT', // '编辑', '系统管理-系统参数-系统参数-编辑'
  'SYSTEM_PARAMETER_DMA_EDIT', // '编辑', '系统管理-系统参数-分区计量参数-编辑'
  'SYSTEM_PARAMETER_DMA_VIEW', // '查看', '系统管理-系统参数-分区计量参数-查看'

  'SYSTEM_ROLE', //  '角色管理','系统管理-角色管理(二级菜单)'
  'SYSTEM_ROLE_MANAGEMENT', // '角色管理', '系统管理-角色管理-角色管理(三级菜单)'
  'SYSTEM_ROLE_MANAGEMENT_VIEW', // '查看', '系统管理-角色管理-角色管理-查看'
  'SYSTEM_ROLE_MANAGEMENT_ADD', // '新增', '系统管理-角色管理-角色管理-新增'
  'SYSTEM_ROLE_MANAGEMENT_EDIT', // '编辑', '系统管理-角色管理-角色管理-编辑'
  'SYSTEM_ROLE_MANAGEMENT_DELETE', // '删除', '系统管理-角色管理-角色管理-删除'

  'SYSTEM_USER_MANAGEMENT', // '用户管理', '系统管理-角色管理-用户管理(三级菜单)'
  'SYSTEM_USER_MANAGEMENT_VIEW', // '查看', '系统管理-角色管理-用户管理-查看'
  'SYSTEM_USER_MANAGEMENT_ADD', // '新增', '系统管理-角色管理-用户管理-新增'
  'SYSTEM_USER_MANAGEMENT_EDIT', // '编辑', '系统管理-角色管理-用户管理-编辑'
  'SYSTEM_USER_MANAGEMENT_DELETE', // '删除', '系统管理-角色管理-用户管理-删除'
];

export function getPermissions() {
  const permissions = storage.get(PERMISSION_STORAGE_KEY);

  if (!permissions || !Array.isArray(permissions)) return [];

  return permissions.filter(i => i.isChecked).map(i => i.code);
}

export function setGlobalPermissions() {
  if (!globalPermissions || !globalPermissions.length) {
    globalPermissions = getPermissions();
  }
}

export const checkPermission = (permissionKey: string | string[] | undefined) => {
  if (!PERMISSIONS_ENABLE) return true;

  setGlobalPermissions();

  if (!permissionKey) return false;

  if (typeof permissionKey === 'string') {
    return globalPermissions.includes(permissionKey);
  }

  if (Array.isArray(permissionKey) && permissionKey.length > 0) {
    return !!permissionKey.find(i => globalPermissions.includes(i));
  }

  return false;
};

export function ReactChildrenFilter(children) {
  return React.Children.map(children, child => {
    if (!child.props) return child;

    if (
      Object.prototype.hasOwnProperty.call(child.props, PERMISSION_KEY) &&
      !child.props[PERMISSION_KEY]
    )
      return null;

    if (!child.props[PERMISSION_KEY]) return child;

    if (!checkPermission(child.props[PERMISSION_KEY])) return null;

    return child;
  });
}

export function PermissionWrapper({ children, permissionKey }) {
  setGlobalPermissions();

  if (permissionKey) {
    if (checkPermission(permissionKey)) return children;

    return null;
  }

  return ReactChildrenFilter(children);
}

export default PermissionWrapper;

export function filterPermissionRoutes(routes) {
  return routes.filter(item => item && item.name && item.path && checkPermission(item.authKey));
}

export function getPermissionTabsByRoutes(routes, isTriggerRoutePush?) {
  const tabs = routes
    .filter(i => checkPermission(i.authKey))
    .map(i => ({
      key: i.path,
      title: i.name,
    }));
  if (isTriggerRoutePush) {
    router.push(tabs[0].key);
  }
  return tabs;
}

export const permissionMap = {};
permissionList.map(i => permissionMap[i]);
