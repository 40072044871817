import React from 'react';
import { Icon } from 'antd';
import refreshPng from '@/assets/table/refresh.png';
import addPng from '@/assets/table/add.png';
import deleteAllPng from '@/assets/table/delete_all.png';
import deletePng from '@/assets/table/delete.png';
import settingPng from '@/assets/table/setting.png';
import exportPng from '@/assets/table/export.png';
import setColumnPng from '@/assets/table/set_column.png';
import loadingImg from '@/assets/loading.gif';
import screenSvg from '@/assets/screen.svg';

const imgMap = {
  refresh: refreshPng,
  add: addPng,
  deleteAll: deleteAllPng,
  delete: deletePng,
  setting: settingPng,
  export: exportPng,
  setColumn: setColumnPng,
};

export const TableIcon = ({ id, ...restProps }) => (
  <img src={imgMap[id]} title={id} alt={id} style={{ cursor: 'pointer' }} {...restProps} />
);

export const LoadingIcon = props => <img src={loadingImg} alt="loading" {...props} />;

export const WarnIcon = () => <Icon type="exclamation-circle" style={{ marginRight: 3 }} />;

export const ScreenIcon = props => <Icon component={screenSvg} {...props} />;
