import React, { PureComponent } from 'react';
import classNames from 'classnames';
import config from './typeConfig';
import styles from './index.less';

export interface ExceptionProps {
  className: React.CSSProperties;
  type: string;
  title: string;
  desc: string;
  img: any;
}

class Exception extends PureComponent<ExceptionProps> {
  render() {
    const { className, type, title, desc, img, ...rest } = this.props;
    const pageType = type in config ? type : '404';
    const clsString = classNames(styles.exception, className);
    return (
      <div className={clsString} {...rest}>
        <div className={styles.imgBlock}>
          <div
            className={styles.imgEle}
            style={{ backgroundImage: `url(${img || config[pageType].img})` }}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>{title || config[pageType].title}</div>
          <div className={styles.desc}>{desc || config[pageType].desc}</div>
        </div>
      </div>
    );
  }
}

export default Exception;
