import { Reducer } from 'redux';
import { Subscription } from 'dva';
import { Effect } from '@/models/connect.d';
import services from '@/services';

export interface UserManageModelState {
  dataSource: [];
  userManagePagination: object;
  userOrgPermission: [];
  allOrgPermission: [];
}

export interface UserManageModelType {
  namespace: 'userManagement';
  state: UserManageModelState;
  effects: {
    query: Effect;
    add: Effect;
    remove: Effect;
    update: Effect;
  };
  reducers: {
    getUserInfo: Reducer<UserManageModelState>;
  };
  subscriptions: { setup: Subscription };
}

export default {
  namespace: 'userManagement',
  state: {
    dataSource: [],
    userOrgPermission: [],
    allOrgPermission: [],
  },
  effects: {
    *add({ payload }, { call, put }) {
      const res = yield call(services.user.add, payload);
      if (res.success) {
        yield put({
          type: 'addUserInfo',
          payload: res.result,
        });
      }
      return res;
    },
    *remove({ payload }, { call, put }) {
      const res = yield call(services.user.remove, payload);
      if (res.success) {
        yield put({
          type: 'removeUserInfo',
          payload: res.result,
        });
      }
      return res;
    },
    *update({ payload }, { call, put }) {
      const res = yield call(services.user.update, payload);
      if (res.success) {
        yield put({
          type: 'updateUserInfo',
          payload: res.result,
        });
      }
      return res;
    },
    *getAllOrgPermissions({ payload }, { call, put }) {
      const res = yield call(services.organization.tree, payload);
      if (res.success) {
        yield put({
          type: 'allOrgPermissions',
          payload: res.result,
        });
      }
      return res;
    },
    *getUserOrgPermissions({ payload }, { call, put }) {
      const res = yield call(services.organization.tree, payload);
      if (res.success) {
        yield put({
          type: 'userOrgPermissions',
          payload: res.result,
        });
      }
      return res;
    },
    *addOrg({ payload }, { call, put }) {
      const res = yield call(services.rbac.addOrg, payload);
      if (res.success) {
        yield put({
          // type: 'addAccountOrg',
          payload: res.result,
        });
      }
      return res;
    },
    *updateOrg({ payload }, { call, put }) {
      const res = yield call(services.rbac.updateOrg, payload);
      if (res.success) {
        yield put({
          // type: 'updateAccountOrg',
          payload: res.result,
        });
      }
      return res;
    },
  },
  reducers: {
    addUserInfo(state) {
      return {
        ...state,
      };
    },
    removeUserInfo(state) {
      return {
        ...state,
      };
    },
    updateUserInfo(state) {
      // console.log(payload);
      return {
        ...state,
      };
    },
    allOrgPermissions(state, { payload }) {
      return {
        ...state,
        allOrgPermission: payload,
      };
    },
    userOrgPermissions(state, { payload }) {
      // console.log(payload);
      return {
        ...state,
        userOrgPermission: payload,
      };
    },
  },
  subscriptions: {},
};
