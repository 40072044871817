import moment from 'moment-timezone';

export const DATE_FORMATS = {
  yyyy_MM_dd: 'YYYY-MM-DD HH:mm:ss',
  dd_MM_yyyy: 'DD-MM-YYYY HH:mm:ss',
  MMMM_yyyy: 'MMMM YYYY',
  HH_mm: 'HH:mm',
};

export const DATE_FORMATS_DD_MM_YY = {
  yyyy_MM_dd: 'YYYY-MM-DD',
  dd_MM_yyyy: 'DD-MM-YYYY',
};

export const DATE_FORMATS_HH_MM_SS = {
  yyyy_MM_dd: 'HH:mm:ss',
  dd_MM_yyyy: 'HH:mm:ss',
};

export const DATE_FORMATS_DD_HH_MM = {
  yyyy_MM_dd: 'MM-DD HH:mm',
  dd_MM_yyyy: 'DD-MM HH:mm',
};

export const DATE_FORMATS_JQ = {
  yyyy_MM_dd: 'yy-mm-dd',
  dd_MM_yyyy: 'dd-mm-yy',
};

export const DEFAULT_TIMEZONE = 'UTC';

export class DateFormat {
  format: string;

  timeZone: string;

  constructor(format: string, timeZone: string) {
    this.format = format || DATE_FORMATS.yyyy_MM_dd;
    this.timeZone = timeZone;
  }
}

export function formatRaw(timeStamp = 0, format: string, timeZone: string): string {
  return moment(timeStamp)
    .tz(timeZone || DEFAULT_TIMEZONE)
    .format(format);
}

// 将时间戳转换为日期
export function formatTimeStamp(
  timeStamp = 0,
  dateFormat: DateFormat = new DateFormat(DATE_FORMATS.yyyy_MM_dd, DEFAULT_TIMEZONE)
): string {
  if (!timeStamp) return '';
  return moment(timeStamp)
    .tz(dateFormat.timeZone ? dateFormat.timeZone : DEFAULT_TIMEZONE)
    .format(
      DATE_FORMATS[dateFormat.format] ? DATE_FORMATS[dateFormat.format] : DATE_FORMATS.yyyy_MM_dd
    );
}

export function formatTimeStampWithOffset(
  timeStamp = 0,
  dateFormat: DateFormat = new DateFormat(DATE_FORMATS.yyyy_MM_dd, DEFAULT_TIMEZONE),
  offsetToSubtract: moment = moment.duration(0)
): string {
  return moment(timeStamp)
    .tz(dateFormat.timeZone ? dateFormat.timeZone : DEFAULT_TIMEZONE)
    .subtract(offsetToSubtract)
    .format(
      DATE_FORMATS[dateFormat.format] ? DATE_FORMATS[dateFormat.format] : DATE_FORMATS.yyyy_MM_dd
    );
}

export function formatTimeStampDailyWithOffset(
  timeStamp = 0,
  dateFormat: DateFormat = new DateFormat(DATE_FORMATS_DD_MM_YY.yyyy_MM_dd, DEFAULT_TIMEZONE),
  offsetToSubtract: moment = moment.duration(0)
): string {
  return moment(timeStamp)
    .tz(dateFormat.timeZone ? dateFormat.timeZone : DEFAULT_TIMEZONE)
    .subtract(offsetToSubtract)
    .format(
      DATE_FORMATS_DD_MM_YY[dateFormat.format]
        ? DATE_FORMATS_DD_MM_YY[dateFormat.format]
        : DATE_FORMATS_DD_MM_YY.yyyy_MM_dd
    );
}

export function offsetTimeStamp(
  timeStamp = 0,
  offsetToSubtract: moment = moment.duration(0)
): string {
  return moment(timeStamp)
    .subtract(offsetToSubtract)
    .valueOf();
}

// 将日期转换成时间戳
export function parseToTimeStamp(
  dateAsString: string,
  dateFormat: DateFormat = new DateFormat(DATE_FORMATS.yyyy_MM_dd, DEFAULT_TIMEZONE)
): number {
  if (!dateAsString || typeof dateAsString !== 'string') {
    return 0;
  }
  return (
    moment
      .tz(
        dateAsString,
        DATE_FORMATS[dateFormat.format] ? DATE_FORMATS[dateFormat.format] : DATE_FORMATS.yyyy_MM_dd,
        dateFormat.timeZone ? dateFormat.timeZone : DEFAULT_TIMEZONE
      )
      .unix() * 1000
  );
}

export function parseTimeStampToDate(
  timeStamp = 0,
  dateFormat: DateFormat = new DateFormat(DATE_FORMATS.yyyy_MM_dd, DEFAULT_TIMEZONE)
): Date {
  return moment(timeStamp)
    .tz(dateFormat.timeZone ? dateFormat.timeZone : DEFAULT_TIMEZONE)
    .toDate();
}

export const getOffset = (
  dateFormat: DateFormat = new DateFormat(DATE_FORMATS.yyyy_MM_dd, DEFAULT_TIMEZONE)
) =>
  moment()
    .tz(dateFormat.timeZone ? dateFormat.timeZone : DEFAULT_TIMEZONE)
    .format('Z');
