export const measRoutes = [
  {
    path: '/supply/meas/new',
    name: '最新抄表',
    authKey: 'SUPPLY_MEAS_NEW_VIEW',
    icon: 'supplyLocal',
    component: './Supply/Meas/New',
    hideInMenu: true,
  },
  {
    path: '/supply/meas/consumption',
    name: '消耗管理',
    authKey: 'SUPPLY_CONSUMPTION_VIEW',
    icon: 'supplyLocal',
    component: './Supply/Meas/Consumption',
    hideInMenu: true,
  },
  {
    path: '/supply/meas/history',
    name: '历史抄表',
    authKey: 'SUPPLY_MEAS_HISTORY_VIEW',
    icon: 'supplyLocal',
    component: './Supply/Meas/History',
    hideInMenu: true,
  },
];

export const deviceRoutes = [
  {
    path: '/device/meter',
    name: '仪表管理',
    authKey: 'DEVICE_METER_VIEW',
    icon: 'deviceLocal',
    component: './Device/MeterManagement',
    hideInMenu: true,
  },
  {
    path: '/device/concentrator',
    name: '集中器管理',
    authKey: 'DEVICE_CONCENTRATOR_VIEW',
    icon: 'deviceLocal',
    component: './Device/ConcentratorManagement',
    hideInMenu: true,
  },
];

export const dmaRoutes = [
  {
    path: '/dma/manager',
    name: '分区管理',
    authKey: 'DMA_MANAGEMENT_VIEW',
    icon: 'measurementLocal',
    component: './Dma/Manager',
    hideInMenu: true,
  },
  {
    path: '/dma/leakage',
    name: '漏损分析',
    authKey: 'DMA_LEAKAGE_VIEW',
    icon: 'measurementLocal',
    component: './Dma/Leakage',
    hideInMenu: true,
  },
];

export const alarmRoutes = [
  {
    path: '/alarm/meter',
    name: '报警仪表',
    authKey: 'ALARM_METER_VIEW',
    icon: 'alarmLocal',
    component: './Alarm/AlarmMeter',
    hideInMenu: true,
  },
  {
    path: '/alarm/history',
    name: '历史报警',
    authKey: 'ALARM_HISTORY_VIEW',
    icon: 'alarmLocal',
    component: './Alarm/HistoryAlarm',
    hideInMenu: true,
  },
  {
    path: '/alarm/rule',
    name: '报警规则',
    authKey: 'ALARM_RULE_VIEW',
    icon: 'alarmLocal',
    component: './Alarm/AlarmRule',
    hideInMenu: true,
  },
];

export const customerRoutes = [
  {
    path: '/customer/organization',
    name: '组织管理',
    authKey: 'CUSTOMER_ORGANIZATION_VIEW',
    icon: 'customerLocal',
    component: './Customer/OrganizationManagement',
    hideInMenu: true,
  },
  {
    path: '/customer/customers',
    name: '客户信息',
    authKey: 'CUSTOMER_INFORMATION_VIEW',
    icon: 'customerLocal',
    component: './Customer/CustomerInformation',
    hideInMenu: true,
  },
];

export const systemRoutes = [
  {
    path: '/system/param',
    name: '系统参数',
    authKey: 'SYSTEM_PARAMETER_SYSTEM_VIEW',
    icon: 'systemLocal',
    components: {
      default: './System/SystemParameter',
      left: './System/SystemParameter',
      right: './System/DmaParameter',
    },
    hideInMenu: true,
  },
  {
    path: '/system/roles',
    name: '角色管理',
    authKey: 'SYSTEM_ROLE_MANAGEMENT_VIEW',
    icon: 'systemLocal',
    component: './System/RolesManagement',
    components: {
      default: './System/RolesManagement',
      left: './System/RolesManagement',
      right: './System/UserManagement',
    },
    hideInMenu: true,
  },
];

const routes = [
  {
    path: '/',
    component: '../layouts/BlankLayout',
    routes: [
      {
        path: '/login',
        component: '../layouts/UserLayout',
        routes: [
          {
            name: 'login',
            path: '/login',
            component: './Login',
          },
        ],
      },
      {
        path: '/license',
        // component: '../layouts/UserLayout',
        routes: [
          {
            name: 'license',
            path: '/license',
            component: './License',
          },
        ],
      },
      {
        path: '/',
        component: '../layouts/BasicLayout',
        Routes: ['src/pages/Authorized'],
        routes: [
          {
            path: '/',
            redirect: './Dashboard',
          },
          {
            path: '/dashboard',
            name: '首页',
            authKey: 'DASHBOARD_VIEW',
            icon: 'dashboardLocal',
            component: './Dashboard',
          },

          {
            path: '/supply',
            name: '供水管理',
            authKey: 'SUPPLY_VIEW',
            icon: 'supplyLocal',
            component: './Supply',
            exact: false,
            routes: [
              {
                path: '/supply',
                redirect: '/supply/meas',
              },
              {
                path: '/supply/meas',
                name: '最新抄表',
                icon: 'supplyLocal',
                component: './Supply/Meas',
                routes: measRoutes,
                hideInMenu: true,
              },
              {
                path: '/supply/meas/new/:id',
                name: '抄表详情',
                icon: 'supplyLocal',
                hideInMenu: true,
              },
              {
                path: '/supply/meas/consumption/:id',
                name: '消耗详情',
                icon: 'supplyLocal',
                hideInMenu: true,
              },
              // {
              //   path: '/supply/record/:id',
              //   name: 'record',
              //   icon: 'supplyLocal',
              //   component: './Supply/Details',
              //   hideInMenu: true,
              // },
            ],
          },

          {
            path: '/dma',
            name: '分区管理',
            authKey: 'DMA_VIEW',
            icon: 'measurementLocal',
            component: './Dma',
            exact: false,
            routes: [
              {
                path: '/dma',
                redirect: '/dma/manager',
              },
              {
                path: '/dma',
                name: '分区管理',
                icon: 'measurementLocal',
                routes: dmaRoutes,
                hideInMenu: true,
              },
            ],
          },

          {
            path: '/alarm',
            name: '报警管理',
            authKey: 'ALARM_VIEW',
            icon: 'alarmLocal',
            component: './Alarm',
            exact: false,
            routes: [
              {
                path: '/alarm',
                redirect: '/alarm/meter',
              },
              {
                path: '/alarm',
                name: '报警管理',
                icon: 'alarmLocal',
                routes: alarmRoutes,
                hideInMenu: true,
              },
            ],
          },

          {
            path: '/customer',
            name: '客户管理',
            authKey: 'CUSTOMER_VIEW',
            icon: 'customerLocal',
            component: './Customer',
            routes: [
              {
                path: '/customer',
                redirect: '/customer/organization',
              },
              {
                path: '/customer',
                name: '组织管理',
                icon: 'customerLocal',
                routes: customerRoutes,
                hideInMenu: true,
              },
            ],
          },

          {
            path: '/device',
            name: '设备管理',
            authKey: 'DEVICE_VIEW',
            icon: 'deviceLocal',
            component: './Device',
            routes: [
              {
                path: '/device',
                redirect: '/device/meter',
              },
              {
                path: '/device',
                name: '仪表管理',
                icon: 'deviceLocal',
                routes: deviceRoutes,
                hideInMenu: true,
              },
              {
                path: '/device/:id',
                name: '仪表详情',
                icon: 'deviceLocal',
                hideInMenu: true,
              },
            ],
          },
          {
            path: '/concentrator_detail/:id',
            name: '集中器详情',
            icon: 'deviceLocal',
            component: './Device/ConcentratorDetail',
            hideInMenu: true,
          },

          {
            path: '/system',
            name: '系统管理',
            authKey: 'SYSTEM_VIEW',
            icon: 'systemLocal',
            component: './System',
            routes: [
              {
                path: '/system',
                redirect: '/system/param',
              },
              {
                path: '/system',
                name: '系统参数',
                icon: 'systemLocal',
                routes: systemRoutes,
                hideInMenu: true,
              },
            ],
          },
          {
            path: '/userManage',
            name: 'userManage',
            icon: 'userManageLocal',
            component: './System/UserManagement',
            hideInMenu: true,
          },
        ],
      },
    ],
  },
];

export default routes;
