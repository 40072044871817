// import { Reducer } from 'redux';
import { Subscription } from 'dva';
import { Effect } from '@/models/connect.d';
import services from '@/services';

// export interface DashboardState {}

export interface DashboardType {
  namespace: 'dashboard';
  state: /* DashboardState */ any;
  effects: {
    getTotal: Effect;
  };
  reducers: {
    getTotal: /* Reducer<DashboardState> */ any;
  };
  subscriptions: { setup: Subscription };
}

export default {
  namespace: 'dashboard',
  state: {},
  effects: {
    *getTotal({ payload }, { call, put }) {
      const res = yield call(services.dashboard.total, payload);
      if (res.success) {
        yield put({
          type: 'get_total',
          payload: res.result,
        });
      }
      return res;
    },
    *getLeakage({ payload }, { call, put }) {
      const res = yield call(services.dashboard.leakage, payload);
      if (res.success) {
        yield put({
          type: 'get_leakage',
          payload: res.result,
        });
      }
      return res;
    },
    *getAlarmReadingRate({ payload }, { call, put }) {
      const res = yield call(services.dashboard.alarmReadingRate, payload);
      if (res.success) {
        yield put({
          type: 'get_alarmReadingRate',
          payload: res.result,
        });
      }
      return res;
    },
  },
  reducers: {
    get_total(state) {
      return {
        ...state,
      };
    },
    get_leakage(state) {
      return {
        ...state,
      };
    },
    get_alarmReadingRate(state) {
      return {
        ...state,
      };
    },
  },
};
