import React from 'react';
import { Router as DefaultRouter, Route, Switch } from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@tmp/history';
import RendererWrapper0 from '/Users/编程项目/wspn-frontend/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BlankLayout" */ '../../layouts/BlankLayout'),
          LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BlankLayout').default,
    routes: [
      {
        path: '/login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
              LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/UserLayout').default,
        routes: [
          {
            name: 'login',
            path: '/login',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Login" */ '../Login'),
                  LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Login').default,
            exact: true,
          },
        ],
      },
      {
        path: '/license',
        routes: [
          {
            name: 'license',
            path: '/license',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__License" */ '../License'),
                  LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../License').default,
            exact: true,
          },
        ],
      },
      {
        path: '/',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
              LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                .default,
            })
          : require('../../layouts/BasicLayout').default,
        Routes: [require('../Authorized').default],
        routes: [
          {
            path: '/',
            redirect: '/Dashboard',
            exact: true,
          },
          {
            path: '/dashboard',
            name: '首页',
            authKey: 'DASHBOARD_VIEW',
            icon: 'dashboardLocal',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Dashboard" */ '../Dashboard'),
                  LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Dashboard').default,
            exact: true,
          },
          {
            path: '/supply',
            name: '供水管理',
            authKey: 'SUPPLY_VIEW',
            icon: 'supplyLocal',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Supply" */ '../Supply'),
                  LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Supply').default,
            exact: false,
            routes: [
              {
                path: '/supply',
                redirect: '/supply/meas',
                exact: true,
              },
              {
                path: '/supply/meas',
                name: '最新抄表',
                icon: 'supplyLocal',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "p__Supply" */ '../Supply/Meas'),
                      LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Supply/Meas').default,
                routes: [
                  {
                    path: '/supply/meas/new',
                    name: '最新抄表',
                    authKey: 'SUPPLY_MEAS_NEW_VIEW',
                    icon: 'supplyLocal',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "p__Supply" */ '../Supply/Meas/New'),
                          LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Supply/Meas/New').default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    path: '/supply/meas/consumption',
                    name: '消耗管理',
                    authKey: 'SUPPLY_CONSUMPTION_VIEW',
                    icon: 'supplyLocal',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "p__Supply" */ '../Supply/Meas/Consumption'),
                          LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Supply/Meas/Consumption').default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    path: '/supply/meas/history',
                    name: '历史抄表',
                    authKey: 'SUPPLY_MEAS_HISTORY_VIEW',
                    icon: 'supplyLocal',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "p__Supply" */ '../Supply/Meas/History'),
                          LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Supply/Meas/History').default,
                    hideInMenu: true,
                    exact: true,
                  },
                ],
                hideInMenu: true,
              },
              {
                path: '/supply/meas/new/:id',
                name: '抄表详情',
                icon: 'supplyLocal',
                hideInMenu: true,
                exact: true,
              },
              {
                path: '/supply/meas/consumption/:id',
                name: '消耗详情',
                icon: 'supplyLocal',
                hideInMenu: true,
                exact: true,
              },
            ],
          },
          {
            path: '/dma',
            name: '分区管理',
            authKey: 'DMA_VIEW',
            icon: 'measurementLocal',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Dma" */ '../Dma'),
                  LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Dma').default,
            exact: false,
            routes: [
              {
                path: '/dma',
                redirect: '/dma/manager',
                exact: true,
              },
              {
                path: '/dma',
                name: '分区管理',
                icon: 'measurementLocal',
                routes: [
                  {
                    path: '/dma/manager',
                    name: '分区管理',
                    authKey: 'DMA_MANAGEMENT_VIEW',
                    icon: 'measurementLocal',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "p__Dma" */ '../Dma/Manager'),
                          LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Dma/Manager').default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    path: '/dma/leakage',
                    name: '漏损分析',
                    authKey: 'DMA_LEAKAGE_VIEW',
                    icon: 'measurementLocal',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "p__Dma" */ '../Dma/Leakage'),
                          LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Dma/Leakage').default,
                    hideInMenu: true,
                    exact: true,
                  },
                ],
                hideInMenu: true,
              },
            ],
          },
          {
            path: '/alarm',
            name: '报警管理',
            authKey: 'ALARM_VIEW',
            icon: 'alarmLocal',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Alarm" */ '../Alarm'),
                  LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Alarm').default,
            exact: false,
            routes: [
              {
                path: '/alarm',
                redirect: '/alarm/meter',
                exact: true,
              },
              {
                path: '/alarm',
                name: '报警管理',
                icon: 'alarmLocal',
                routes: [
                  {
                    path: '/alarm/meter',
                    name: '报警仪表',
                    authKey: 'ALARM_METER_VIEW',
                    icon: 'alarmLocal',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "p__Alarm" */ '../Alarm/AlarmMeter'),
                          LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Alarm/AlarmMeter').default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    path: '/alarm/history',
                    name: '历史报警',
                    authKey: 'ALARM_HISTORY_VIEW',
                    icon: 'alarmLocal',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "p__Alarm" */ '../Alarm/HistoryAlarm'),
                          LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Alarm/HistoryAlarm').default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    path: '/alarm/rule',
                    name: '报警规则',
                    authKey: 'ALARM_RULE_VIEW',
                    icon: 'alarmLocal',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "p__Alarm" */ '../Alarm/AlarmRule'),
                          LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Alarm/AlarmRule').default,
                    hideInMenu: true,
                    exact: true,
                  },
                ],
                hideInMenu: true,
              },
            ],
          },
          {
            path: '/customer',
            name: '客户管理',
            authKey: 'CUSTOMER_VIEW',
            icon: 'customerLocal',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Customer" */ '../Customer'),
                  LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Customer').default,
            routes: [
              {
                path: '/customer',
                redirect: '/customer/organization',
                exact: true,
              },
              {
                path: '/customer',
                name: '组织管理',
                icon: 'customerLocal',
                routes: [
                  {
                    path: '/customer/organization',
                    name: '组织管理',
                    authKey: 'CUSTOMER_ORGANIZATION_VIEW',
                    icon: 'customerLocal',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "p__Customer" */ '../Customer/OrganizationManagement'),
                          LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Customer/OrganizationManagement').default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    path: '/customer/customers',
                    name: '客户信息',
                    authKey: 'CUSTOMER_INFORMATION_VIEW',
                    icon: 'customerLocal',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "p__Customer" */ '../Customer/CustomerInformation'),
                          LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Customer/CustomerInformation').default,
                    hideInMenu: true,
                    exact: true,
                  },
                ],
                hideInMenu: true,
              },
            ],
          },
          {
            path: '/device',
            name: '设备管理',
            authKey: 'DEVICE_VIEW',
            icon: 'deviceLocal',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Device" */ '../Device'),
                  LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Device').default,
            routes: [
              {
                path: '/device',
                redirect: '/device/meter',
                exact: true,
              },
              {
                path: '/device',
                name: '仪表管理',
                icon: 'deviceLocal',
                routes: [
                  {
                    path: '/device/meter',
                    name: '仪表管理',
                    authKey: 'DEVICE_METER_VIEW',
                    icon: 'deviceLocal',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "p__Device" */ '../Device/MeterManagement'),
                          LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Device/MeterManagement').default,
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    path: '/device/concentrator',
                    name: '集中器管理',
                    authKey: 'DEVICE_CONCENTRATOR_VIEW',
                    icon: 'deviceLocal',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "p__Device" */ '../Device/ConcentratorManagement'),
                          LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../Device/ConcentratorManagement').default,
                    hideInMenu: true,
                    exact: true,
                  },
                ],
                hideInMenu: true,
              },
              {
                path: '/device/:id',
                name: '仪表详情',
                icon: 'deviceLocal',
                hideInMenu: true,
                exact: true,
              },
            ],
          },
          {
            path: '/concentrator_detail/:id',
            name: '集中器详情',
            icon: 'deviceLocal',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Device__ConcentratorDetail" */ '../Device/ConcentratorDetail'),
                  LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../Device/ConcentratorDetail').default,
            hideInMenu: true,
            exact: true,
          },
          {
            path: '/system',
            name: '系统管理',
            authKey: 'SYSTEM_VIEW',
            icon: 'systemLocal',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__System" */ '../System'),
                  LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../System').default,
            routes: [
              {
                path: '/system',
                redirect: '/system/param',
                exact: true,
              },
              {
                path: '/system',
                name: '系统参数',
                icon: 'systemLocal',
                routes: [
                  {
                    path: '/system/param',
                    name: '系统参数',
                    authKey: 'SYSTEM_PARAMETER_SYSTEM_VIEW',
                    icon: 'systemLocal',
                    components: {
                      default: './System/SystemParameter',
                      left: './System/SystemParameter',
                      right: './System/DmaParameter',
                    },
                    hideInMenu: true,
                    exact: true,
                  },
                  {
                    path: '/system/roles',
                    name: '角色管理',
                    authKey: 'SYSTEM_ROLE_MANAGEMENT_VIEW',
                    icon: 'systemLocal',
                    component: __IS_BROWSER
                      ? _dvaDynamic({
                          component: () =>
                            import(/* webpackChunkName: "p__System" */ '../System/RolesManagement'),
                          LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                            .default,
                        })
                      : require('../System/RolesManagement').default,
                    components: {
                      default: './System/RolesManagement',
                      left: './System/RolesManagement',
                      right: './System/UserManagement',
                    },
                    hideInMenu: true,
                    exact: true,
                  },
                ],
                hideInMenu: true,
              },
            ],
          },
          {
            path: '/userManage',
            name: 'userManage',
            icon: 'userManageLocal',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__System__UserManagement" */ '../System/UserManagement'),
                  LoadingComponent: require('/Users/编程项目/wspn-frontend/src/components/PageLoading/index')
                    .default,
                })
              : require('../System/UserManagement').default,
            hideInMenu: true,
            exact: true,
          },
        ],
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
