import { Reducer } from 'redux';
import { Subscription } from 'dva';
import { Effect } from '@/models/connect.d';
import services from '@/services';
import { storage } from '@/utils';

export interface SupplyState {
  measTree: [];
  consumptionTree: [];
  measMeterDetailTabs: [];
}

export interface SupplyType {
  namespace: 'supply';
  state: SupplyState;
  effects: {
    queryMeterListByMeterNumber: Effect;
  };
  reducers: {
    supply: Reducer<SupplyState>;
  };
  subscriptions: { setup: Subscription };
}

export default {
  namespace: 'supply',
  state: {
    measTree: [],
    consumptionTree: [],
    measMeterDetailTabs: [],
  },
  effects: {
    *queryMeterListByMeterNumber({ payload }, { call, put }) {
      const res = yield call(services.supply.queryMeterListByMeterId, payload);
      if (res.success) {
        yield put({
          type: 'getMeterListByMeterNumber',
          payload: res.result,
        });
      }
      return res;
    },
    *fetchTree({ payload }, { call, put }) {
      const res = yield call(services.organization.tree, payload);
      if (res.success) {
        yield put({
          type: 'getMeasTree',
          payload: res.result,
        });
      }
      return res;
    },
    *getMeterReadingRate({ payload }, { call, put }) {
      const res = yield call(services.measVal.meterReadingRate, payload);
      if (res.success) {
        yield put({
          type: 'get_MeterReadingRate',
          payload: res.result,
        });
      }
      return res;
    },
    *fetchConsumptionTree({ payload }, { call, put }) {
      const res = yield call(services.organization.tree, payload);
      if (res.success) {
        yield put({
          type: 'getConsumptionTree',
          payload: res.result,
        });
      }
      return res;
    },
    *onChangeMeasMeterDetailTab({ payload }, { put }) {
      if (payload) {
        yield put({
          type: 'changeMeasMeterTabs',
          payload,
        });
      }
    },
    *onCloseMeasMeterDetailTab({ payload }, { put }) {
      if (payload) {
        yield put({
          type: 'closeMeasMeterTab',
          payload,
        });
      }
    },
  },
  reducers: {
    getMeterListByMeterNumber(state) {
      return {
        ...state,
      };
    },
    getMeasTree(state, { payload }) {
      return {
        ...state,
        measTree: payload,
      };
    },
    get_MeterReadingRate(state) {
      return {
        ...state,
      };
    },
    getConsumptionTree(state, { payload }) {
      return {
        ...state,
        consumptionTree: payload,
      };
    },
    changeMeasMeterTabs(state, { payload }) {
      const meterTabs = storage.get('measMeterDetailTabs')
        ? storage.get('measMeterDetailTabs')
        : [];
      // const newMeterTabs = [];
      // meterTabs.forEach(item => {
      //   if (item.meterId === payload[0].meterId) {
      //     newMeterTabs.push(payload[0]);
      //   } else {
      //     newMeterTabs.push(item);
      //   }
      // });
      // storage.set('measMeterDetailTabs', Array.from(arrReducer([...newMeterTabs, ...payload])));
      storage.set('measMeterDetailTabs', Array.from(arrReducer([...meterTabs, ...payload])));
      return {
        ...state,
        // measMeterDetailTabs: Array.from(arrReducer([...newMeterTabs, ...payload])),
        measMeterDetailTabs: Array.from(arrReducer([...state.measMeterDetailTabs, ...payload])),
      };
    },
    closeMeasMeterTab(state, { payload }) {
      const meterTabs = storage.get('measMeterDetailTabs')
        ? storage.get('measMeterDetailTabs')
        : [];
      const newMeterTabs = [...meterTabs];
      let i = -1;
      let x = 0;
      newMeterTabs.forEach((item, index) => {
        if (item.meterId === payload.meterId) {
          i = index;
          x = 1;
          newMeterTabs.splice(i, x);
        }
      });
      storage.set('measMeterDetailTabs', newMeterTabs);
      return {
        ...state,
        measMeterDetailTabs: meterTabs,
      };
    },
    closeMeasMeterTabByMeterNumber(state, { payload }) {
      const meterTabs = storage.get('measMeterDetailTabs')
        ? storage.get('measMeterDetailTabs')
        : [];
      const newMeterTabs = [...meterTabs];
      let i = -1;
      let x = 0;
      newMeterTabs.forEach((item, index) => {
        if (item.meterNumber === payload.meterNumber) {
          i = index;
          x = 1;
        }
        newMeterTabs.splice(i, x);
      });
      storage.set('measMeterDetailTabs', newMeterTabs);
      return {
        ...state,
        measMeterDetailTabs: meterTabs,
      };
    },
  },
  subscriptions: {},
};

// 数组去重
function arrReducer(arr) {
  let arrTemp = arr;
  const obj = {};
  arrTemp = arrTemp.reduce((cur, next) => {
    // eslint-disable-next-line no-unused-expressions
    obj[next.meterNumber] ? '' : (obj[next.meterNumber] = true && cur.push(next));
    return cur;
  }, []);
  return arrTemp;
}
