export default {
  email: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
  password: /[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/,
  phoneNumber: /^(?=([^0-9]*[0-9]){6,}[^0-9]*$)[+]?(?=[0-9#,;*]*$)/,
  len8To20: /^.{8,20}$/,
  notnegativefloat: /^[1-9]\d*\.\d*|^[1-9]\d*|0\.\d*[1-9]\d*|0?\.0+|0$/,
  time: /^(2[0-3]|[0-1]?\d)$/,
  percent: /^[1-9]\d\.\d*$|^\d\.\d*$|^([1-9]?\d|100)$/,
  positiveinteger: /^[0-9]*[1-9][0-9]*$/,
  number: /^\+?[0-9][0-9]*$/,
  numberRegexMap: /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:([.,])\d+)?$/,
};
