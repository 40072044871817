import { Reducer } from 'redux';
import { Subscription } from 'dva';
import { Effect } from '@/models/connect.d';
import services from '@/services';

export interface WaterDmaState {
  waterDmaTree: [];
  dmaParam: [];
  dmaUnMeterTree: [];
}

export interface WaterDmaType {
  namespace: 'waterDma';
  state: WaterDmaState;
  effects: {
    getOrgaizationTree: Effect;
  };
  reducers: {
    getOrgaizationTree: Reducer<WaterDmaState>;
  };
  subscriptions: { setup: Subscription };
}

export default {
  namespace: 'waterDma',
  state: {
    waterDmaTree: [],
    dmaParam: [],
    dmaUnMeterTree: [],
  },
  effects: {
    *getWaterDamTree({ payload }, { call, put }) {
      const res = yield call(services.waterDma.waterDmaTree, {}, payload);
      if (res.success) {
        yield put({
          type: 'water_Dma_Tree',
          payload: res.result,
        });
      }
      return res;
    },
    *createWaterDmaParam({ payload }, { call, put }) {
      const res = yield call(services.waterDma.waterDmaParam, payload);
      if (res.success) {
        yield put({
          payload: res.result,
        });
      }
    },
    *getDmaParam({ payload }, { call, put }) {
      const res = yield call(services.waterDma.dmaParam, payload);
      if (res.success) {
        yield put({
          type: 'get_dma_param',
          payload: res.result,
        });
      }
      return res;
    },
    *getDmaName({ payload }, { call, put }) {
      const res = yield call(services.waterDma.dmaName, payload);
      if (res.success) {
        yield put({
          type: 'get_dmaName',
          payload: res.result,
        });
      }
      return res;
    },
    *getDmaUnMeterTree({ payload }, { call, put }) {
      const res = yield call(services.organization.tree, payload);
      if (res.success) {
        yield put({
          type: 'get_DmaUnMeterTree',
          payload: res.result,
        });
      }
    },
    *createDmaAndMeter({ payload }, { call, put }) {
      const res = yield call(services.waterDma.dmaAndMeter, payload);
      if (res.success) {
        yield put({
          type: 'create_DmaAndMeter',
          payload: res.result,
        });
      }
      return res;
    },
    *queryDmaName({ payload }, { call, put }) {
      const res = yield call(services.waterDma.queryDmaName, {}, payload);
      if (res.success) {
        yield put({
          type: 'query_DmaName',
          payload: res.result,
        });
      }
      return res;
    },
    *deleteDma({ payload }, { call, put }) {
      const res = yield call(services.waterDma.deleteDma, {}, payload);
      if (res.success) {
        yield put({
          type: 'delete_Dma',
          payload: res.result,
        });
      }
      return res;
    },
  },
  reducers: {
    water_Dma_Tree(state, { payload }) {
      return {
        ...state,
        waterDmaTree: payload,
      };
    },
    get_dma_param(state, { payload }) {
      return {
        ...state,
        dmaParam: payload,
      };
    },
    get_dmaName(state) {
      return {
        ...state,
      };
    },
    get_DmaUnMeterTree(state, { payload }) {
      return {
        ...state,
        dmaUnMeterTree: payload,
      };
    },
    create_DmaAndMeter(state) {
      return {
        ...state,
      };
    },
    query_DmaName(state) {
      return {
        ...state,
      };
    },
    delete_Dma(state) {
      return {
        ...state,
      };
    },
  },
};
