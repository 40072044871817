import { Reducer } from 'redux';
import { Subscription } from 'dva';
import { Effect } from '@/models/connect.d';
import services from '@/services';

export interface CustomerManagementState {
  organization: [];
  dataSource: [];
  orgList: [];
  customerPagination: object;
  customerMeterPagination: object;
  orgTreeData: [];
  customerMeter: [];
  meterById: object;
  alarmTreeData: [];
}

export interface CustomerManagementType {
  namespace: 'customerManagement';
  state: CustomerManagementState;
  effects: {
    getOrgaizationTree: Effect;
  };
  reducers: {
    getOrgaizationTree: Reducer<CustomerManagementState>;
  };
  subscriptions: { setup: Subscription };
}

export default {
  namespace: 'customerManagement',
  state: {
    organization: [],
    dataSource: [],
    orgList: [],
    customerPagination: {
      current: 1,
      total: 0,
      pageSize: 10,
    },
    customerMeterPagination: {
      current: 1,
      total: 0,
      pageSize: 10,
    },
    orgTreeData: [],
    customerMeter: [],
    meterById: null,
  },
  effects: {
    *fetchTree({ payload }, { call, put }) {
      const res = yield call(services.organization.tree, payload);
      if (res.success) {
        yield put({
          type: 'getOrganizationTree',
          payload: res.result,
        });
      }
      return res;
    },
    *fetchData({ payload }, { call, put }) {
      const res = yield call(services.organization.customers, payload.body, payload.query);
      if (res.success) {
        yield put({
          type: 'getCustomerData',
          payload: res.result,
        });
      }
    },
    *addCustomer({ payload }, { call, put }) {
      const res = yield call(services.customer.add, payload);
      if (res.success) {
        yield put({
          type: 'createCustomer',
          payload: res.result,
        });
      }
      return res;
    },
    *deleteCustomer({ payload }, { call, put }) {
      const res = yield call(services.customer.deleteCustomer, payload);
      if (res.success) {
        yield put({
          type: 'removeCustomer',
          payload: res.result,
        });
      }
      return res;
    },
    *deleteCustomers({ payload }, { call, put }) {
      const res = yield call(services.customer.deleteCustomers, payload);
      if (res.success) {
        yield put({
          type: 'removeCustomers',
          payload: res.result,
        });
      }
      return res;
    },
    *expCustomer({ payload }, { call, put }) {
      const res = yield call(services.customer.expCustomer, payload);
      if (res.success) {
        yield put({
          type: 'exportCustomer',
        });
      }
      return res;
    },
    *addOrganization({ payload }, { call, put }) {
      const res = yield call(services.organization.addOrganization, payload);
      if (res.success) {
        yield put({
          type: 'add',
          payload: res.result,
        });
      }
      return res;
    },
    *updateOrganization({ payload }, { call, put }) {
      const res = yield call(services.organization.updateOrganization, payload);
      if (res.success) {
        yield put({
          type: 'update',
          payload: res.result,
        });
      }
      return res;
    },
    *deleteOrganization({ payload }, { call, put }) {
      const res = yield call(services.organization.updateOrganization, payload);
      if (res.success) {
        yield put({
          type: 'delete',
          payload: res.result,
        });
      }
      return res;
    },
    *updateCustomer({ payload }, { call, put }) {
      const res = yield call(services.customer.update, payload);
      if (res.success) {
        yield put({
          type: 'update_Customer',
          payload: res.result,
        });
      }
      return res;
    },
    *getCustomById({ payload }, { call, put }) {
      const res = yield call(services.customer.getCustomById, payload);
      if (res.success) {
        yield put({
          type: 'getCustom',
          payload: res.result,
        });
      }
      return res;
    },
    *orgReaInfo({ payload }, { call, put }) {
      const res = yield call(services.organization.orgReaInfo, payload);
      if (res.success) {
        yield put({
          type: 'reaInfo',
          payload: res.result,
        });
      }
      return res;
    },
    *deleteOrgs({ payload }, { call, put }) {
      const res = yield call(services.organization.deleteOrgs, {}, payload);
      if (res.success) {
        yield put({
          type: 'deleteOrg',
          payload: res.result,
        });
      }
      return res;
    },
    *orgNameStrFormat({ payload }, { call, put }) {
      const res = yield call(services.organization.orgNameStrFormat, payload);
      if (res.success) {
        yield put({
          type: 'strFormat',
          payload: res.result,
        });
      }
      return res;
    },
    *orgTreeData({ payload }, { call, put }) {
      const res = yield call(services.organization.tree, payload);
      if (res.success) {
        yield put({
          type: 'getOrgTreeData',
          payload: res.result,
        });
      }
      return res;
    },
    *createCustomerMeter({ payload }, { call, put }) {
      const res = yield call(services.device.addCustomerMeter, payload);
      if (res.success) {
        yield put({
          type: 'addCustomerMeter',
          payload: res.result,
        });
      }
    },
    *queryCustomerMeterById({ payload }, { call, put }) {
      const res = yield call(services.device.queryCustomerMeterById, payload);
      if (res.success) {
        yield put({
          type: 'getCustomerMeterById',
          payload: res.result,
        });
      }
    },
    *deleteCustomerMeter({ payload }, { call, put }) {
      const res = yield call(services.device.deleteCustomerMeter, payload);
      if (res.seccess) {
        yield put({
          payload: res.result,
        });
      }
      return res;
    },
    *expOrg({ payload }, { call, put }) {
      const res = yield call(services.organization.expOrg, payload.body, payload.query);
      if (res.success) {
        yield put({
          type: 'exp_Org',
          payload: res.result,
        });
      }
    },
    *alarmTreeData({ payload }, { call, put }) {
      const res = yield call(services.organization.tree, payload);
      if (res.success) {
        yield put({
          type: 'getAlarmTreeData',
          payload: res.result,
        });
      }
      return res;
    },
  },
  reducers: {
    getOrganizationTree(state, { payload }) {
      return {
        ...state,
        organization: payload,
      };
    },
    getCustomerData(state, { payload }) {
      return {
        ...state,
        dataSource: payload.data,
        customerPagination: {
          ...state.customerPagination,
          total: payload.pagination.total,
          current: payload.pagination.current,
          pageSize: payload.pagination.size,
        },
      };
    },
    createCustomer(state) {
      return {
        ...state,
      };
    },
    update_Customer(state) {
      return {
        ...state,
      };
    },
    removeCustomer(state) {
      return {
        ...state,
      };
    },
    removeCustomers(state) {
      return {
        ...state,
      };
    },
    getCustomerMeterById(state, { payload }) {
      return {
        ...state,
        customerMeter: payload.meters,
        customerMeterPagination: {
          ...state.customerMeterPagination,
          total: payload.meterCount,
          current: 1,
          pageSize: 10,
        },
      };
    },
    exportCustomer(state) {
      return {
        ...state,
      };
    },
    add(state) {
      return {
        ...state,
      };
    },
    update(state) {
      return {
        ...state,
      };
    },
    delete(state) {
      return {
        ...state,
      };
    },
    getCustom(state) {
      return {
        ...state,
      };
    },
    reaInfo(state) {
      return {
        ...state,
      };
    },
    deleteOrg(state) {
      return {
        ...state,
      };
    },
    strFormat(state) {
      return {
        ...state,
      };
    },
    getOrgTreeData(state, { payload }) {
      return {
        ...state,
        orgTreeData: payload,
      };
    },
    getCustomerMeter(state, { payload }) {
      return {
        ...state,
        customerMeter: payload.data,
        customerMeterPagination: {
          ...state.customerMeterPagination,
          total: payload.pagination.total,
          current: payload.pagination.current,
          pageSize: payload.pagination.size,
        },
      };
    },
    addCustomerMeter(state) {
      return {
        ...state,
      };
    },
    exp_Org(state) {
      return {
        ...state,
      };
    },
    getAlarmTreeData(state, { payload }) {
      return {
        ...state,
        alarmTreeData: payload,
      };
    },
  },
};
