import axios from 'axios';
import get from 'lodash/get';
import { apiPrefix } from '@/services/api';
import { storage } from '@/utils';
import { setProjectInfo } from '@/utils/projectInfo';

let isAlreadyFetchingAccessToken = false;

let subscribers: any[] = [];

function onAccessTokenFetched(accessToken: string) {
  subscribers.forEach(callback => callback(accessToken));
  subscribers = [];
}

function addSubscriber(callback: any) {
  subscribers.push(callback);
}

export async function resetTokenAndReattemptRequest(error: any) {
  try {
    const { response: errorResponse } = error;

    const resetToken = getRefreshToken();

    if (!resetToken) {
      return Promise.reject(error);
    }

    const retryOriginalRequest = new Promise(resolve => {
      addSubscriber((accessToken: string) => {
        errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
        resolve(axios(errorResponse.config));
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      const response = await axios.post(
        `${apiPrefix}/auth/refresh`,
        {},
        {
          headers: getAuthHeaders(),
        }
      );

      if (!response.data) {
        return Promise.reject(error);
      }

      const newToken = get(response, 'data.accessToken');
      const projectInfo = get(response, 'data.projectInfo');
      setProjectInfo(projectInfo);

      setAccessToken(newToken);

      isAlreadyFetchingAccessToken = false;

      onAccessTokenFetched(newToken);
    }

    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}

export function getAccessToken() {
  return storage.get('wspn-accessToken');
}

export function getAuthHeaders() {
  const headers: { Authorization?: string } = {
    Authorization: undefined,
  };

  const refreshToken = getRefreshToken();

  if (refreshToken) {
    headers.Authorization = `Bearer ${refreshToken}`;
  }

  return headers;
}

export function getAccessAuthHeaders() {
  const headers: { Authorization?: string } = {
    Authorization: undefined,
  };

  const refreshToken = getAccessToken();

  if (refreshToken) {
    headers.Authorization = `Bearer ${refreshToken}`;
  }

  return headers;
}

export function getRefreshToken() {
  return storage.get('wspn-refreshToken');
}

export function setAccessToken(accessToken: string) {
  if (!accessToken) return;
  storage.set('wspn-accessToken', accessToken);
}

export function setRefreshToken(refreshToken: string) {
  if (!refreshToken) return;
  storage.set('wspn-refreshToken', refreshToken);
}

export function isTokenExpiredError(errorResponse: any): boolean {
  return errorResponse.status === 401 && storage.get('wspn-refreshToken');
}
