import { stringify } from 'qs';
import isPlainObject from 'lodash/isPlainObject';
import { request } from '@/utils';
import { apis } from './api';

const gen = params => {
  let url = params;
  let method = 'GET';
  let otherOptions = '{}';
  const paramsArray = params.split(' ');

  if (paramsArray.length === 2) {
    [method, url] = paramsArray;
  }

  if (paramsArray.length === 3) {
    [method, url, otherOptions] = paramsArray;
  }
  return (data, query) =>
    request({
      url: url + getUrl(query),
      data,
      method,
      otherOptions,
    });
};

const APIFunction = {};

Object.keys(apis).forEach(api => {
  APIFunction[api] = {};
  Object.keys(apis[api]).forEach(key => {
    APIFunction[api][key] = gen(apis[api][key]);
  });
});

export default APIFunction;

function getUrl(query) {
  if (!isPlainObject(query)) {
    if (query !== undefined) {
      return query;
    }
    return '';
  }
  return `?${stringify(query)}`;
}
